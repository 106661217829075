<template>
<div class="desc-container3" :style="{ backgroundImage: 'url(' + images.wypocznij + ')' }">
   <!-- <div class="map-desc-container3">-->
      <div class="description4">
      <div class="map-desc-container5">
        <div class="right-desc">
            <div class="inside-right-desc2">
              <h2>Gdy sam odpoczynek nie wystarczy... podreperuj zdrowie</h2>
              <p class="italic">Z uwagi na konieczność prawidłowej regeneracji, rehabilitacji i
              działań profilaktycznych w ramach dbałości o zdrowie własne i naszych najbliższych,
              chcemy pisać, promować i polecać wszystkie te miejsca, gdzie przy pomocy najwyższej klasy
              specjalistów i najlepszej aparatury, jeżeli zajdzie taka potrzeba możecie Państwo podreperować
              swoje zdrowie.</p>
            </div>
      </div>
      <div class="map-cont">
        <div @click="goToMap" class="go-to-map-container">
          <img :src="images.Poland" alt="mapa Polski"/>
          <button class="find-smth2">Znajdź ośrodek</button>
        </div>
      </div>
      <!--</div>-->
          </div>
      </div>
    </div>
  <div class="second-desc-container">
      <div class="column">
          <img :src="images.rehabilitacja2" alt="wypoczynek"/>
      </div>
      <div class="column">
          <img :src="images.rehabilitacja3" alt="wypoczynek"/>
      </div>
      <div class="column">
          <img :src="images.rehabilitacja1" alt="wypoczynek"/>
      </div>
  </div>
  <div class="products-container">
    <div>
      <p class="map-desc">
        Wybierz województwo, a następnie wybierz rodzaj ośrodka.
      </p>
    </div>
    <div class="map-cont1">
      <PolandMap 
        @clickWoj="clickWoj"
      />
    </div>
    <div class="{active: hover}" id="tooltip3">{{hoverWoj}}</div>
  <!--  <div>{{ choosenCompany.nazwa_producenta }}</div>-->
    <div v-if="clickedWoj !== ''" class="filters2" id="filters2">
      <div class="filter">
      </div>
      <div class="filter">
      <div>
        <div class="filter-container">
            <div class="onefilter-container">
                <h3>Udogodnienia</h3>
                <div v-for="type in this.allCategories" :key="type" class="check-box" >
                    <label class="container">{{ type }}
                        <input
                        type="checkbox"
                        id="subcategory"
                        name="subcategory"
                        :value="type"
                    />
                        <span class="checkmark"></span>
                    </label><br/>
                </div>
            </div>
            <!--end of filters container-->
        </div>
      </div>
      </div>
      <div class="filter-button-container">
        <button @click="filter" class="filter-button">Filtruj</button>
      </div>
    </div>
    <div v-if="isShownChoosenCompany" class="choosen-company">
      <div class="background"></div>
      <div class="choosen-company-box">
      <img :src="images.cross" class="cross" @click="closeWindow"/>
        <div class="column-container">
          <div class="right-column">
            <div class="icons">
              <div>
                <div class="icon-box">
                  <img :src="images.location" alt="location" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_producenta }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.phone" alt="phone number" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.tel1 }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.mail" alt="mail" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_mailowy }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.website" alt="website" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_strony }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.facebook" alt="facebook" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.facebook }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.insta" alt="instagram" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.instagram }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-column">
            <div class="contact-and-name">
              <div class="logo-box">
                <img :src="choosenCompany.logo" class="logo" />
              </div>
              <div class="prod-name">{{ choosenCompany.nazwa_producenta }}</div>
            </div>
          </div>
        </div>
      <div class="description">
        <p class="company-desc">
          <span class="bolder-comp-desc">Tu będzie opis firmy i/lub działalności i/lub produktu zgodnie z Twoją decyzją.</span> Lorem Ipsum is simply dummy text of the printing
          and typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived
          not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in
          the 1960s with the release of Letraset sheets containing Lorem Ipsum
          passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum.
        </p>
      </div>
      </div>
    </div>
    <div class="boxes-container" v-if="companiesAreVisible">
        <!-- PRODUCTS CARUSEL -->
    <div class="products-carusel-above-container">
    <div class="products-carusel-conteiner">
        <!--<Flicking :options="{ circular: true, stopOnHover: true, preventClickOnDrag: false}" :plugins="pluginsProducts">-->
            <div class="company-box2">
              <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 1</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Radosna 56, 99-999 Jawor</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
                <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 2</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Miła 45, 99-999 Kłodzko</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 2','Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img :src="images.imgLogo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div class="be-partner">
            <p>Partnerzy</p>
          </div>
          <div>
            <img :src="images.imgLogoHelps" class="logo-small1" alt="Twoje logo"/>
          </div>
        </div>
        <div class="select">
          <router-link to="/bePartner"><button>Zostań Partnerem</button></router-link>
        </div>
      </div>
        <!--</Flicking>-->
      </div>
          <div class="products-carusel-conteiner1">
        <!--<Flicking :options="{ circular: true, stopOnHover: true, preventClickOnDrag: false}" :plugins="pluginsProducts">-->
            <div class="company-box2">
              <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 1</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Radosna 56, 99-999 Jawor</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
                <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 2</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Miła 45, 99-999 Kłodzko</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 2','Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img :src="images.imgLogo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div class="be-partner">
            <p>Partnerzy</p>
          </div>
          <div>
            <img :src="images.imgLogoHelps" class="logo-small1" alt="Twoje logo"/>
          </div>
        </div>
        <div class="select">
          <router-link to="/bePartner"><button>Zostań Partnerem</button></router-link>
        </div>
      </div>
        <!--</Flicking>-->
      </div>
    </div>
    <!--END PRODUCTS CARUSEL -->
      <!--Test companies -->
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 1</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5>Imię i Nazwisko TEST 5</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
    <!--Test companies -->
      <div class="company-box1">
        <div class="box-first-part">
          <div>
            <img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5>Nazwa Firmy TEST 1</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
      <div class="box-first-part">
        <div>
          <img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/>
        </div>
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
        <div class="box-first-part">
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych')">Wybierz</button>
        </div>
      </div>
       <div class="company-box1">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 5</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
         <!--Test companies -->
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 1</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
      <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych')">Wybierz</button>
        </div>
      </div>
       <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 5</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
          <!--COMPANIES FIRST PLACE-->
      <div
        v-for="company in companiesFirstPlace"
        v-bind:key="company.id"
        class="company-box"
      >
        <div class="box-first-part">
          <div v-if="company.wyrLogo=='1'">
            <img :src="company.logo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5 :class="{fontAndSite: company.wyrCzcionka=='1'}">{{ company.nazwa_producenta }}</h5>
          </div>
          <div v-if="company.wyrCzcionka=='1'" class="website-container">
            <p class="website">{{ company.adres_strony }}</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>{{ company.adres_producenta }}</p>
          </div>
          <!--<div><img :src="company.logo" class="logo"/></div>-->
        </div>
        <div class="select">
          <button @click="showCompany(company.id)">Wybierz</button>
        </div>
      </div>
      <!--END COMPANIES FIRST PLACE -->
      <div
        v-for="company in wojCompanies"
        v-bind:key="company.id"
        class="company-box"
      >
        <div class="box-first-part">
          <div v-if="company.wyrLogo=='1'">
            <img :src="company.logo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5 :class="{fontAndSite: company.wyrCzcionka=='1'}">{{ company.nazwa_producenta }}</h5>
          </div>
          <div v-if="company.wyrCzcionka=='1'" class="website-container">
            <p class="website">{{ company.adres_strony}}</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>{{ company.adres_producenta }}</p>
          </div>
          <!--<div><img :src="company.logo" class="logo"/></div>-->
        </div>
        <div class="select">
          <button @click="showCompany(company.id)">Wybierz</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompaniesDataService from "../GetCompanies.js";
import categories from "../categoriesRehabilitation.js";
//import { ref } from 'vue'
import axios from "axios";
import location from "../photos/home.png";
import phone from "../photos/phone.png";
import mail from "../photos/mail.png";
import website from "../photos/website.png";
import facebook from "../photos/facebook.png";
import insta from "../photos/insta.png";
import certyfikat from "../photos/certyfikat.png";
import cross from "../photos/cross.png";
import wypocznij from "../photos/Spa_wypoczynek.jpeg";
import rehabilitacja1 from "../photos/rehabilitacja1.png";
import rehabilitacja2 from "../photos/rehabilitacja2.png";
import rehabilitacja3 from "../photos/rehabilitacja3.png";
import imgLogo from '../photos/Mendel_Helps_logo_male.png';
import imgLogoHelps from '../photos/Mendel_Helps_logo.png';
import Poland from "../photos/poland.png";
import PolandMap from './PolandMap.vue';
export default {
  name: "TheCompanies",
  components: {
    PolandMap
  },
  data() {
    return {
      companies: [],
      message: "",
      clickedWoj: "",
      clickedWojId: "",
      hoverWoj: "",
      wojCompanies: [],
      companiesFirstPlace:[],
      photos: [],
      isShownChoosenCompany: false,
      choosenCompany: [],
      allCategories: categories,
      choosenCategory: "",
      choosenSubcategory: "",
      typesCategory: [],
      companiesAreVisible: false,
      hover2: true,
      images: {
        location: location,
        phone: phone,
        mail: mail,
        website: website,
        facebook: facebook,
        insta: insta,
        certyfikat: certyfikat,
        cross: cross,
        wypocznij: wypocznij,
        rehabilitacja1: rehabilitacja1,
        rehabilitacja3: rehabilitacja3,
        rehabilitacja2: rehabilitacja2,
        Poland: Poland,
        imgLogo: imgLogo,
        imgLogoHelps: imgLogoHelps
      },
    };
  },
  watch: {
    choosenCategory() {
      let category = this.choosenCategory;
      if (category !== "") {
        let typesArray = this.allCategories.find(
          (option) => option.name == category
        );
        this.typesCategory = typesArray.types;
        console.log(typesArray);
      }
    },
  },
  methods: {
    refreshUsers() {
      CompaniesDataService.retrieveAllCompanies().then((res) => {
        this.companies = res.data;
        console.log(this.companies);
      });
    },
    clickWoj(woj, id) {
      this.companiesAreVisible = false;
      var allMap = document.getElementsByClassName('woj');
      var index = 0, length = allMap.length;
      for ( ; index < length; index++) {
        allMap[index].style.fill = "#6d8b33";
    }
      this.clickedWojId = id;
      var clickedMapPart = document.getElementById(this.clickedWojId);
      clickedMapPart.style.fill = '#d2ca61';
      this.clickedWoj = woj;
      var newTable = this.companies.filter((el) => el.województwo == woj);
      var compFirstPlace = newTable.filter((el)=> el.wyrPierwszeMiesca == '1');
      this.companiesFirstPlace = compFirstPlace;
      console.log(compFirstPlace);
       var restCompanies = newTable.filter((el)=> el.wyrPierwszeMiesca == '0');
      this.wojCompanies = restCompanies;
      console.log(newTable);
      this.companiesAreVisible = true;
      console.log(this.companiesAreVisible);
      console.log(this.clickedWoj);
    },
    filter() {
      // var newTable = this.companies.filter((el)=>el.name==this.filters.name && (el.businessType == this.filters.businessType));
      console.log(this.choosenCategory);
      console.log(this.choosenSubcategory);
      var newTable = this.companies.filter(this.filterValues);
      var compFirstPlace = newTable.filter((el)=> el.wyrPierwszeMiesca == '1');
      this.companiesFirstPlace = compFirstPlace;
      console.log(compFirstPlace);
       var restCompanies = newTable.filter((el)=> el.wyrPierwszeMiesca == '0');
      this.wojCompanies = restCompanies;
      this.companiesAreVisible = true;
      this.choosenCategory = '';
      this.choosenSubcategory = '';
    },
    filterValues(el) {
      if (this.choosenCategory == "" && this.choosenSubcategory == "") {
        console.log(this.clickedWoj);
        return el.województwo == this.clickedWoj;
      } else if (this.choosenCategory != "" && this.choosenSubcategory == "") {
        return (
          el.category == this.choosenCategory &&
          el.województwo == this.clickedWoj
        );
      } else if (this.choosenCategory != "" && this.choosenSubcategory != "") {
        return (
          el.category == this.choosenCategory && el.subcat.includes(this.choosenSubcategory) && el.województwo == this.clickedWoj
        );
      } else {
        return el;
      }
    },
    showCompany(id) {
      var choosenComp = this.companies.filter((el) => el.id == id);
      this.choosenCompany = choosenComp[0];
      console.log(this.choosenCompany);
      console.log(this.choosenCompany.nazwa_producenta);
      console.log(this.choosenCompany.adres_mailowy);
      this.isShownChoosenCompany = true;
    },
    showTestCompany(nazwa, adres, logoUrl) {
      var choosenCompTest = {
       adres_mailowy: "twojmail@gmail.com",
       adres_producenta: adres,
       adres_strony: "www.twojastrona.pl",
       category: "TEST",
       facebook: "https://twojfacebook.pl",
       id: "39",
       instagram: "https://twojistagram.pl",
       logo: logoUrl,
       nazwa_producenta: nazwa,
       opis: "Tu będzie opis Twojej firmy. \r\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
       subcat: "TEST",
       tel1: "999 999 999",
       tel2: "99 999 99 99",
       tel3: "999 999 999",
       województwo: "dolnośląskie"
    }
    this.choosenCompany = choosenCompTest;
    this.isShownChoosenCompany = true;
    },
    closeWindow() {
      this.isShownChoosenCompany = false;
    },
        runningTooltip3(e) {
                var tooltipSpan = document.getElementById('tooltip3');
                var x = e.clientX,
                    y = e.clientY;
                
                var isMobile = /Mobi|Android/i.test(navigator.userAgent);

                if (isMobile) {
                    tooltipSpan.style.top = (y + 450) + 'px';
                    tooltipSpan.style.left = (x + 20) + 'px';
                } else {
                    tooltipSpan.style.top = (y + 20) + 'px';
                    tooltipSpan.style.left = (x + 20) + 'px';
                }
    },
    showState3(name) {
      // Sprawdzamy czy szerokość ekranu jest większa niż 768px
      if (window.innerWidth > 768) {
        const element = document.getElementById('woj-text3');
        if (element) {
          element.textContent = name;
          element.style.display = 'block';
        }
      }
    },
    hideWojText() {
      const element = document.getElementById('woj-text3');
      if (element) {
        element.style.display = 'none';
      }
    },
    goToMap() {
      const map = document.getElementById("map3");
      map.scrollIntoView();
    }
  },
  created() {
    axios
      .get(`./fetchRehabilitation.php`)
      .then((response) => response)
      .then((res) => {
        this.companies = res.data;
        console.log("companies");
        console.log(this.companies);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.log("server is down!!");
      });
        //       var map = document.getElementById('map');
        // map.onmouseleave = function () {
        // var tooltipSpan = document.getElementById('tooltip');
        // console.log(tooltipSpan);
        // tooltipSpan.style.display = 'none';
        // }
  },
};
</script>

<style scoped>
.desc-container3 {
    height: 350px;
    background-size: cover;
    background-position: center;
    margin: -30px 0 0 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;
}
.inside-right-desc2 {
  width: 725px;
  text-align: justify;
}
.inside-right-desc2 p {
  text-align: justify;
}
.map-desc-container5 {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    width: 1450px;
    padding: 0;
    margin: 0;
}
.right-desc {
  width: 60%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.desc-container3 img {
  width: 300px;
  margin-bottom: 15px;
  margin-left: 20px;
  transition: 0.3s ease-out;
}
.desc-container3 img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.description4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 1450px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.description4 h2 {
  padding: 0;
  color: white;
  font-size: 25px;
  text-align: center;
}
.description4 p {
  padding: 20px;
  margin:0;
  font-size: 17px;
  color: white;
  line-height: 22px;
}
.description4 p span {
  font-weight: bolder;
}
/*.right-desc {
  margin-left: 50px;
    width: 725px;
    align-self: center;
    margin-bottom: 30px;
}*/
.right-desc p {
  text-align: justify;
}
.go-to-map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  position: relative;
}
.find-smth2:hover {
  font-weight: 600;
  color: #3084E2;
}
.second-desc-container {
  width: 1450px;
  margin: 60px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.second-desc-container p {
  text-align: justify;
}
.column {
  width: 33%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.column img {
  float: left;
  width: 300px;
}
.bigger {
  font-size: 23px;
  font-weight: bold;
}
.find-smth2 {
  font-weight: 600;
  font-size: 20px;
  width: 220px;
  text-align: center;
  padding: 5px 25px;
  margin: 5px auto 10px auto;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:110px;
  left: 65px;
}
.find-smth:hover {
    background: none;
  font-weight: 600;
  color: #3084E2;
}
.italic {
  font-style: italic;
  font-size: 19px;
  font-weight: 500;
  text-align: center
  }
.products-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}
.map3 {
  position: relative;
  width: 612.75696px;
  height: 577.23169px;
  margin: 30px auto;
}

.map3 svg {
  width: 100%;
  height: 100%;
  display: block;
}

.map-paths {
  width: 100%;
  height: 100%;
}

.map-paths path {
  cursor: pointer;
  fill: #6d8b33;
  transition: fill 0.3s ease;
}

.map-paths path:hover {
  fill: #d2ca61;
}

.region-labels {
  position: relative;
  width: 100%;
  height: 100%;
}

.region-name {
  position: absolute;
  font-size: 10px;
  color: #000000;
  font-weight: bold;
  transform: translate(-50%, -50%);
  font-family: 'Montserrat', sans-serif;
  pointer-events: none;
}

@media (max-width: 768px) {
  .region-name {
    font-size: 10px;
  }
}

.map-desc {
  margin-top: 50px;
  font-size: 19px;
  font-weight: 600;
}
svg text {
  display: none;
  z-index: 10;
}
svg path:hover text {
  display: block;
  z-index: 10;
}
.filter {
  display: inline-block;
}
.filter h3 {
    margin: 10px auto 20px auto;
}
.filter label {

}
.checked-box label {
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
}
.filter-button {
  background-color: #3084E2;
  display: inline-block;
  height: 35px;
  font-size: 17px;
  padding: 8px 30px;
  border: none;
  margin: 0 20px;
}
.filter-button:hover {
  cursor: pointer;
  background-color: #75b1f5;
}
select {
  height: 30px;
  padding: 3px;
  font-size: 17px;
  line-height: 30px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.filters {
  margin: 50px;
}

.filter-container {
    display: flex;
    flex-align: row;
    justify-content: center;
    align-items: flex-start;
}
.products-carusel-above-container {
  width: 1400px;
  overflow:hidden;
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.products-carusel-above-container:hover > .products-carusel-conteiner {
  animation-play-state: paused;
}
.products-carusel-above-container:hover > .products-carusel-conteiner1 {
  animation-play-state: paused;
}
.products-carusel-conteiner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  //animation: 10s ease-in 1s infinite reverse both running carusel;
  animation: 10s linear 0s infinite running carusel;

}
 .products-carusel-conteiner1 {
    display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  //animation: 10s ease-in 1s infinite reverse both running carusel;
  animation: 10s linear 0s infinite running carusel;
 }
@keyframes carusel {
    from {
      display: flex;
      transform: translateX(-50%);
    }

    to {
      display: flex;
      transform: translateX(50%);
    }
  }
.company-box, .company-box1 {
  width: 250px;
  height: 330px;
  margin: 20px;
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
  box-sizing: border-box;
}
.company-box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 330px;
  margin: 20px;
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
  box-sizing: border-box;
}
.box-first-part {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 87%;
}
.company-box h5, .company-box1 h5, .company-box2 h5 {
  margin: 10px 0 20px 0;
}
.company-box h5 {
  font-weight: 400;
  font-size: 19px;
}
.company-box1 h5 {
  font-weight: 700;
  font-size: 19px;
}
.company-box2 h5 {
  font-weight: 700;
  font-size: 19px;
}
.website-container {
  margin: 0;
  padding: 0;
}
.website {
  margin: 0;
  padding: 0;
  font-style: italic;
}
/*styling font-size to bolder distinctions*/
.fontAndSite {
  font-weight: 700!important;
  font-size: 19px;
}
.boxes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  aling-items: center;
  justify-content: center;
  margin: 50px;
  width: 1450px;
}
button {
  margin: 0;
  padding: 15px;
  color: white;
  border-radius: 20px;
  background-color: #5d4db7;
  box-sizing: border-box;
}
.select {
  width: 100%;
  align-self: end;
}
.select button {
  width: 100%;
  align-self: flex-end;
  border: none;
  padding: 10px;
  font-size: 17px;
  border-radius: 20px;
  background-color: #3084e2;
}
.select button:hover {
  background-color: #5e97d5;
}
.logo {
  width: 150px;
}
.logo-small {
  width: 100px;
  margin: 0;
  padding: 0;
}
.logo-small1 {
  width: 180px;
  margin-top:0;
  padding-top:0;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.choosen-company-box {
  position: fixed;
  top: 8vh;
  left: 20%;
  width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 12px;
  border: 5px solid #504DB7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 0px;
  margin: 60px;
}
.column-container {
  position: relative;
  margin: 30px 30px 0 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.left-column,
right-column {
  margin: 20px;
  display: inline-block;
  height: 100%
}
.logo {
  width: 150px;
  margin-bottom: 30px;
}
.prod-name {
  font-size: 35px;
  color: #504DB7;
  margin-top: 30px;
  font-weight: 500;
}
.prod-adres {
  font-size: 17px;
}
.contact-data {
  background-color: #3084e2;
  font-size: 17px;
  padding: 12px 20px;
  color: white;
  margin: 20px;
  clear: both;
}
.bolder-comp-desc {
  font-weight: bolder;
}
.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icons img {
  width: 40px;
  margin-right: 10px;
}
.icon-box {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  clear: both;
}
.icons p {
  font-weight: bold;
  margin: 3px;
  text-align: left;
  float: left;
}
.icons h5 {
  margin: 3px;
  font-weight: 400;
  font-size: 16px;
}
.contact-and-name {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}
.company-desc {
  padding: 30px;
  text-align: justify;
}
.icon-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  aling-items: flex-start;
}
.cross {
  width: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.woj-text {
  font-size: 30px;
  fill: black;
}
#tooltip3 {
    opacity: 1;
    display:none;
    position: fixed;
    color: #3084E2;
    background-color: rgb(240,248,255,0.9);
    padding: 10px 15px;
    border-radius: 15px;
}
.active {
  display: block;
  color: red;
}

/*styling checkboxes*/

.container {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 16px;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-box {
  width: 100%;
}
.onefilter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    width: 700px;
    text-align: left;
}
@media only screen and (min-width: 901px) and (max-width: 1250px) {
.boxes-container {
  width: 900px;
    box-sizing: border-box;
}
.map-desc-container5 {
    width: 900px;
    margin-top: 30px;
    align-items: flex-end;
}
.desc-container div {
  color: white;
}
.map-cont {
  width: 50%;
}
.desc-container img {
  width: 300px;
}
.right-desc {
  width: 100%;
}
.right-desc p {
  text-align: justify;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 22px;
}
.inside-right-desc2 {
  width: 550px;
}
.description4 {
  width: 900px;
  margin:0;
  padding: 0 20px;
}
.description3 h2 {
  font-size: 20px;
}
.second-desc-container  {
  width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.second-desc-container .column img{
  width: 230px;
  margin: 0;
  padding: 0;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  margin: 30px auto;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 900px;
}
.find-smth {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 5px 25px;
  margin: 5px auto 10px auto;
  color: #3084E2;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:110px;
  left: 40px;
}
.find-smth:hover {
  font-weight: 600;
  color: black;
}
.filters {
  display: inline-block;
  width: 900px;
}
.filter label {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
}
.filter-button {
  display: inline-block;
  height: 35px;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
}
@media only screen and (min-width: 541px) and (max-width: 900px) {
.map-desc-container5 {
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    align-items: flex-end;
}
  .desc-container3 {
    height: auto;
    background-size: cover;
    background-position: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.description4 {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  margin: 0px;
}
.map-desc-container3 {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 30px 0 0 0;
    align-items: flex-end;
}
.description4 {
  height: auto;
  margin: 30px 0 0 0;
  padding: 0;
}

.desc-container3 div {
  color: white;
}
.map-cont1 {
  width: 50%;
}
.desc-container3 img {
  width: 200px;
  margin-left: 3px;
}
.right-desc {
  width: 100%;
  margin: 0;
  padding: 0;
}
.right-desc p {
  text-align: justify;
  font-size: 15px;
  paddin: 0;
  margin: 0;
}
.inside-right-desc2 {
  width:auto;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 22px;
}
.description4 h2 {
  font-size: 20px;
}
.second-desc-container  {
  margin: 20px auto 20px auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.second-desc-container .column img{
  width: 90%;
  margin: 0;
  padding: 0;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px auto;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 100%;
}
.find-smth2 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  width: 120px;
  padding: 5px 25px;
  margin: 5px auto 10px auto;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:60px;
  left: 35px;
}
.find-smth:hover {
  font-weight: 600;
  color: black;
  background-color: #3084E2;
}
.filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.onefilter-container {
  width: 100%;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.filter label {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 500;
  font-size: 15px;
}
.check-box {
  width: 100%;
  box-sizing: border-box;
  padding: 3px;
}
.filter-button-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  jusity-content: center;
  align-items: center;
  padding: 10px;
}
.filter-button {
  display: inline-block;
  height: 35px;
  font-size: 16px;
  padding: 8px 100px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.boxes-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  jusity-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.map {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
svg {
  transform: scale(0.7);
}
.choosen-company-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: 3px solid #504DB7;
  margin: 0;
}
.column-container {
  position: relative;
  margin: 15px 10px 15px 10px;
}
.left-column,
right-column {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%
}
.left-column {
  justify-content: center;
  align-items: center;
}
.prod-name {
  font-size: 20px;
  margin-top: 0;
}
.logo {
  width: 70%;
}
.right-column {
  justify-content: center;
  align-items: flex-start;
}
.cross {
  width:20px;
  top: 5px;
  right: 5px;
  z-index: 1000;
}
.company-desc {
  margin: 0;
  padding: 0;
}
.bolder-comp-desc {
  font-weight: bolder;
}
.icon-box {
  margin: 5px;
}
.icon-desc h5 {
  float: left;
  font-size: 14px;
}
.icons img {
  width: 30px;
  margin-right: 5px;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
}
@media only screen and (max-width: 540px) {
.boxes-container {
  width: 100%;
  box-sizing: border-box;
}
  .desc-container3 {
    height: auto;
    background-size: cover;
    background-position: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.description4 {
  padding: 5px;
  margin: 0px;
}
.map-desc-container5 {
    width: 100%;
    flex-direction: column;
    height: auto;
    box-sizing: border-box;
    padding: 30px 0 0 0;
    align-items: center;
}
.description4 {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 0px;
}
.desc-container3 div {
  color: white;
}
.svg {
  width: 50%;
  height: 400px;
  border: 1px solid red;
}
.map-cont {
    display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start;
  width: 150px;
  margin: 5px auto;
}
.desc-container3 img {
  width: 150px;
}
.right-desc {
  width: 100%;
  margin: 0;
  padding: 0;
}
.inside-right-desc2 {
  width: 100%;
  box-sizing: border-box;
}
.right-desc p {
  text-align: justify;
  font-size: 15px;
  paddin: 0;
  margin: 0;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 22px;
}
.description4 h2 {
  font-size: 20px;
}
.second-desc-container  {
  margin: 20px auto 20px auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.second-desc-container .column img{
  width: 90%;
  margin: 0;
  padding: 0;
}
.go-to-map-container img {
  margin-left: 0;
  width: 110px;
}
#tooltip3 {
    opacity: 1;
    display: none;
    position: absolute;
    color: #3084E2;
    background-color: rgba(240, 248, 255, 0.9);
    padding: 10px 15px;
    border-radius: 15px;
    z-index: 1000;
    pointer-events: none;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px auto;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 100%;
}
.find-smth2 {
  font-weight: 600;
  font-size: 13px;
  width: 90px;
  text-align: center;
  padding: 5px 25px;
  margin: 5px auto 10px auto;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:25px;
  left: 25px;
}
.find-smth:hover {
  font-weight: 600;
  color: black;
  background-color: #3084E2;
}
.filters {
  display: inline-block;
  width: 100%;
  box-sizing: border-box
}
.filter label {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
}
.filter-button-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  jusity-content: center;
  align-items: center;
  padding: 10px;
}
.filter-button {
  display: inline-block;
  height: 35px;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.onefilter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.check-box {
  margin: 3px;
  padding: 0;
}
.onefilter-container h3 {
  float: left;
}
.go-to-map-container {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;
}
.map-cont1 {
  width: 100%;
  box-sizing: border-box;
}
.map {
  width: 100%;
  height: auto;
  margin:-100px 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
svg {
  transform: scale(0.5);
}
.choosen-company-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: 3px solid #504DB7;
  margin: 0;
  padding: 0;
}
.column-container {
  position: relative;
  margin: 15px 10px 15px 10px;
}
.left-column,
right-column {
  margin: 10px;
  display: flex;
  flex-direction: column;
  height: 100%
}
.left-column {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.contact-and-name {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.prod-name {
  font-size: 17px;
  margin-top: 0;
}
.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.logo {
  width: 70%;
}
.right-column {
  justify-content: center;
  align-items: flex-start;
}
.cross {
  width:20px;
  top: 5px;
  right: 5px;
  z-index: 1000;
}
.company-desc {
  margin: 0;
  padding: 10px;
}
.icon-box {
  margin: 5px;
}
.icon-desc h5 {
  float: left;
  text-align: left;
  font-size: 14px;
}
.icons img {
  width: 25px;
  margin-right: 5px;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
}

.map-paths path {
  cursor: pointer;
}

.region-labels {
  position: relative;
  width: 100%;
  height: 100%;
}

.region-name {
  position: absolute;
  font-size: 10px;
  color: #000000;
  font-weight: bold;
  transform: translate(-50%, -50%);
  font-family: 'Montserrat', sans-serif;
  pointer-events: none;
}

@media (max-width: 768px) {
  .region-name {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .region-name {
    font-size: 10px;
  }
  
  .map-cont1 {
    height: 400px;  /* zwiększone z 350px na 400px */
    margin-top: -150px;  /* zmienione z 10px na -20px */
    margin-bottom: 120px;  /* zwiększone z 30px na 80px */
    transform: scale(1.2) translateX(-60px);  /* dodane przesunięcie w lewo */
    transform-origin: center center;
    width: 95%;  /* zmniejszona szerokość kontenera */
    margin-left: auto;  /* automatyczne marginesy dla wycentrowania */
    margin-right: auto;
  }

  .map-desc {
    font-size: 16px;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .products-container {
    padding-bottom: 50px;  /* dodany padding na dole */
    align-items: center;  /* wycentrowanie zawartości */
  }

  .map3 {
    display: flex;  /* dodane dla lepszego centrowania */
    justify-content: center;  /* wycentrowanie poziome */
  }
}

.map-cont1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.map3 {
  position: relative;
  width: 612.75696px;
  height: 577.23169px;
  margin: 30px auto;
}

.map3 svg {
  width: 100%;
  height: 100%;
  display: block;
}

@media (max-width: 768px) {
  .map-cont1 {
    height: 400px;
    margin-top: -50px;
    margin-bottom: 50px;
    transform: scale(1) translateX(0);
    transform-origin: center center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
  }

  .map3 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: auto;
    transform: scale(0.9);
    transform-origin: center center;
  }
}

@media only screen and (max-width: 540px) {
  .map-cont1 {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }

  svg {
    transform: scale(0.8);
  }
}
</style>
