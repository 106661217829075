<template>
    <div class="header">
        <div class="logo-description">
            <img :src="images.logo" alt="MendelHelps logo" class="logo"/>
            <div class="sentence1-container"><p class="sentence1">Zawsze, kiedy potrzebujesz pomocy …<img :src="images.logoSentence" class="logo1"/></p></div>
            <div class="choose-us"><p>ZAPRASZAMY DO KORZYSTANIA Z:</p>
                <ul>
                    <li>BAZY PORTALU MENDEL HELPS LIFE</li>
                    <li>NASZEJ WIEDZY</li>
                </ul>
            </div>
        </div>
        <div class="article-part1">
                <p class="cursive">Gdy prawie 35 lat temu jako nastolatek wkraczałem na drogę szeroko rozumianego biznesu
                nie zastanawiałem się, gdzie mnie ta droga zaprowadzi. Chciałem iść, a może dokładniej biec przed siebie i
                w szybkim tempie kojarzonym z biegiem budować, tworzyć, zmieniać otaczającą mnie rzeczywistość, brać udział
                w wielkiej przebudowie świata w jakim przyszło mi żyć. </p> <p class="cursive">Czas mija szybko, co mogę powiedzieć po
                okresie pracy i współpracy z największymi światowymi koncernami, ale także z podmiotami różnej wielkości o
                 typowo polskim kapitale. Budowa, zarządzanie, rozwój i działania sanacyjne, naprawcze wielu firm, krańcowo
                 różnych branż poza sukcesami okupionymi ogromnym zaangażowaniem czasowym i dużymi kosztami na gruncie życia
                  osobistego, dały mi trudne do opisania w kilku słowach możliwości. Poznałem dogłębnie wiele gałęzi gospodarki,
                  począwszy od spożywczej na przemysłowej kończąc. By móc realizować postawione przede mną zadania mieszkałem
                  i tym samym poznałem praktycznie każdą część Polski, zwiedziłem kawał świata oraz
                  nabyłem wiedzę i zdobyłem doświadczenie, którego nigdzie indziej w takim czasie i zakresie zdobyć bym nie
                  zdołał.</p>
                <p class="cursive"> Od kilku lat podążając wspomnianą wyżej drogą biznesu coraz to wygodniejszymi i szybszymi środkami lokomocji,
                 zacząłem częściej spoglądać przez okno prowadzonego pojazdu na otaczający mnie świat. Pomimo dużych prędkości
                 pozytywnych zmian, z uwagi na większe doświadczenie oraz umiejętność dostrzegania detali, zacząłem w przedmiotowym
                 świecie zauważać poza pozytywami i towarzyszącym im stale podnoszącą się jakością naszego życia, również więcej
                 negatywów, dotykających tak podmiotów gospodarczych, firm, jak i każdego z nas z osobna. Odpowiedzią na te problemy ma
                 być projekt Mendel Helps będącym moją własnością. Portal Mendel Helps Life, będący jego ważną częścią, traktowany przeze mnie bardzo poważnie, bardzo profesjonalnie i bardzo osobiście, o czym z pewnością świadczy firmowanie go własnym nazwiskiem ma ambicję, jak sama nazwa wskazuje, pomagać każdemu jego Partnerowi i Użytkownikowi w obszarze szeroko rozumianego zdrowego trybu życia, zgodnie z naczelną zasadą leżącą u podstaw całego projektu Mendel Helps, która brzmi „Zawsze, kiedy potrzebujesz pomocy …Mendel Helps.</p>
                <div class="signature-container">
                    <p class="desc1">Właściciel projektu Mendel Helps</p>
                    <img :src="images.podpis" alt="Mendel Helps" class="signature"/>
                </div>
        </div>
    </div>
    <div class="about-carusel-container">
        <div class="hight-carusel-conteiner">
            <Flicking :options="{ circular: true }" :plugins="plugins2">
                <div class="sentence-container" id="firstSentence1">
                    <p class="sentence">Zawsze, kiedy potrzebujesz pomocy...<img :src="images.imgLogo" alt="Mendel Helps"/></p>
                </div>
                <div class="sentence-container" id="secondSentence1">
                    <p class="sentence">Zawsze, kiedy potrzebujesz pomocy...<img src="../../public/photos/Mendel_Helps_logo.png"/></p>
                </div>
            </Flicking>
        </div>
    </div>
    <div class="second-container-background" :style="{ backgroundImage: 'url(' + images.family + ')' }">
        <div class="header1">
            <div class="logo-description">
            </div>
            <div class="article-part2">
                <p>Pierwsza część projektu, nazwana Mendel Helps Life, jest ukierunkowana na
                pomoc nam wszystkim w zasadniczym dla funkcjonowania społeczeństw obszarze, jakim jest zdrowy tryb życia.</p>
                 <p> Nasi Partnerzy wytwarzający produkty lub świadczący usługi w opisanych kategoriach mają możliwość
                  i szansę zaprezentować siebie, swoje produkty, usługi nie tylko na Portalu, ale także w mediach społecznościowych powiązanych z Mendel Helps Life i tym samym zareklamować się, dotrzeć i nawiązać kontakt z szeroką rzeszą Użytkowników Portalu.
                  Ci ostatni, zwani potocznie konsumentami, zdobywają zaś dzięki
                 Portalowi niecodzienną możliwość bezpośredniego kontaktu i nabycia produktu lub usługi
                  u samego źródła, bez pośrednika i bez dodatkowych jawnych czy ukrytych kosztów. Wszyscy razem zaś poza
                  skonfrontowaniem przekazywanej wiedzy przez Portal z namacalną rzeczywistością produktową, zdobywamy możliwość
                  dotarcia do tak poszukiwanej przez wszystkich, a często brakującej jakości, ponadprzeciętnej jakości jaką musi gwarantować
                  i gwarantuje Portal Mendel Helps Life oraz zaproszeni przez niego do współpracy Partnerzy. Zapraszamy do korzystania z naszej
                  wiedzy i naszej bazy, pamiętaj - Zawsze, kiedy potrzebujesz pomocy …Mendel Helps Life.</p>
            </div>
        </div>
    </div>

</template>

<script>
import Flicking from "@egjs/vue3-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import succes from '../photos/MendelHelpsShowLogo.jpg';
import imgLogo from '../photos/Mendel_Helps_logo.png';
import logo from '../photos/Mendel_Helps_logo_male.png';
import logoSentence from '../photos/Mendel_Helps_sentence.png';
import family from '../photos/family1.png';
import podpis from '../photos/podpis1.png';
    export default {
        components: {
                Flicking: Flicking
            },
        data() {
            return {
                plugins2: [new AutoPlay({ duration: 500, animationDuration: 6000, direction: "NEXT", stopOnHover: true})],
                images: {
                    succes: succes,
                    imgLogo: imgLogo,
                    family: family,
                    logo: logo,
                    podpis: podpis,
                    logoSentence: logoSentence
                }
            }
        },
    }
</script>

<style>
@import url("../../node_modules/@egjs/vue3-flicking/dist/flicking.css");
@import "../../node_modules/@egjs/flicking-plugins/dist/arrow.css";
html, body { height: 100%; width: 100%; margin: 0; }
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 1450px;
    height: 1000px;
    margin: 50px auto 30px auto;
    padding: 0;
    box-sizing: border-box;
}
.header1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 1450px;
    height: 100%;
    margin: 30px auto 0;
    padding: 0;
    box-sizing: border-box;
}
.article-part1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border-radius: 20px;
    height: 100%;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
    width: 50%;
    color: #404040;
    padding-top: 20px;
}
.article-part1 div {
    padding: 0px;
}
.article-part2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    border-radius: 25px;
    line-height: 1.4;
    width: 50%;
    padding: 30px;
    color: black;
    margin-bottom: 30px;
}
.article-part2 div {
    padding: 30px;
}
.logo-description {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 150px;
    padding-bottom: 0;
}
.logo-description p {
    text-align: right;
    margin: 0;
}
.logo {
    width: 350px;
    margin-bottom: 30px;
}
.logo1 {
    display: inline;
    width: 300px;
    margin-top: 20px;
   /* margin-bottom: -20px;*/
}
.choose-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 25px;
    color: #5D4DB7;
    font-weight: 500;
    margin:30px 0 0 0;
    line-height: 1.6;
}
.choose-us p {
    align-self: center;
}
.choose-us ul {
    text-align: center;
    list-style-position: inside;
    margin-bottom: 0;
    padding-inline-start: 0px;
}
.choose-us ul li {
    margin-bottom: 0;
}
.hight-carusel-conteiner {
    margin: 0 auto;
    width: 1450px;
    height: 75px;
    border-radius: 25px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
}
.sentence-container {
    width:100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sentence-container img {
    width: 250px;
    margin-left: 10px;
    margin-top: 10px;
}
.sentence-container p {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sentence {
    color: #303845;
    font-size: 25px;
    margin:0 auto 0px auto;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.sentence1 {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    color: #303845;
    font-size: 25px;
    margin:0 auto 0px auto;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  margin: 30px 0;
  font-style: normal;
  text-align: center;

}
.sentence1-container {
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.sentence span {
    font-size: 50px;
    font-weight: bolder;
}
.article-container {
    margin:0;
    width: 100%;
    background-color:  #2f3644;
    font-weight: 500;
    color: #cca554;
    border-radius: 35px;
}
.article {
    width: 1450px;
    margin: 0 auto;
    padding: 70px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    line-height: 25px;
}
.second-container-background {
    margin:0 0 -25px 0;
    padding: 0;
    height: 850px;
    display: flex;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 1000px;
    background-position: bottom 0 left 0;
    z-index: 100;
}
.article div {
    width: 50%;
}
.article-part1 p{
    margin: 20px;
    text-align: justify;
    font-weight: 500;
}
.article-part2 {
    flex-direction:column;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    padding: 0;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
}
.article-part2 p{
    margin: 20px;
    text-align: justify;
    font-weight: 500;
}
.cursive {
    font-style: italic;
    font-weight: 700;
}
.img-family {
    width: 50%;
    align-self: flex-end;
}
.article-second-part {
    margin: 0 auto;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}
.img-container {
    width: 50%;
    border: 1px solid black;
}
.bottom-text-container {
    text-align: justify;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #a06313;
    margin-bottom: 30px;
}

.bottom-text {
    align-items: center;
    border-radius: 25px;
    background-color: #9abc4c;
    width: 725px;
    padding: 30px;
    color: #a06313;
    margin-bottom: 30px;
    padding: 30px;
}
.article-second-part p {
    align-self: center;
    margin: 30px;
    line-height: 25px;
}
.article-second-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    float: right;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}
.article-part2 p .desc1 {
    margin: 0;
}
.desc1 {
    text-align: right;
    margin: 0;
    padding:0;
}
.desc2 {
    margin: 10px 20px!important;
    padding: 0;
    font-family: "Dancing Script", cursive;
    font-size: 25px;
}
.signature {
    width: 150px;
    margin-top: 0;
    margin-right: 90px;
}
@media only screen and (min-width: 1201px) and (max-width: 1450px) {
.header {
    width: 1200px;
    height: 1100px;
    margin: 30px auto 30px auto;
    padding: 0;
    box-sizing: border-box;
}
.header1 {
    width: 1200px;
    height: auto;
}
.article-part1 div {
    padding: 30px;
}
.article-part2 div {
    padding: 30px;
}
.logo-description {
    margin-right: 110px;
}
.logo {
    width: 320px;
    margin-bottom: 30px;
}
.logo1 {
    width: 250px;
   /* margin-bottom: -20px;*/
}
.choose-us {
    font-size: 23px;
    font-weight: 500;
    margin:20px 0 0 0;
}
.hight-carusel-conteiner {
    width: 1200px;
}
.sentence-container img {
    width: 230px;
}
.sentence {
    font-size: 23px;
}
.article-container {
    margin:0;
    width: 100%;
}
.article {
    width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
}
.second-container-background {
    margin:0 0 -25px 0;
    padding: 0;
    height: 1100px;
    display: flex;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 1000px;
    background-position: bottom 0 left 0;
    z-index: 100;
}
}
@media only screen and (min-width: 901px) and (max-width: 1200px) {
.header {
    width: 900px;
    height: 1350px;
    margin: 30px auto 30px auto;
    padding: 0;
    box-sizing: border-box;
}
.header1 {
    width: 900px;
    height: 100%;
}
.article-part1 div {
    padding: 30px;
}
.article-part2 div {
    padding: 30px;
}
.logo-description {
    margin-right: 30px;
}
.logo {
    width: 250px;
    margin-bottom: 30px;
}
.logo1 {
    width: 250px;
   /* margin-bottom: -20px;*/
}
.choose-us {
    font-size: 20px;
    font-weight: 500;
    margin:20px 0 0 0;
}
.hight-carusel-conteiner {
    width: 900px;
}
.sentence-container img {
    width: 230px;
}
.sentence {
    font-size: 23px;
}
.article-container {
    margin:0;
    width: 100%;
}
.article {
    width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
}
.article-part2 {
    width: 100%;
}
.header1 .logo-description {
    display: none;
}
.second-container-background {
    margin:0 0 -25px 0;
    padding: 0;
    height: 950px;
    display: flex;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 1000px;
    background-position: bottom 0 left 0;
    z-index: 100;
}
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
.header {
    width: 600px;
    height: 2000px;
    margin: 30px auto 30px auto;
    padding: 0;
    box-sizing: border-box;
}
.header1 {
    width: 900px;
    height: 100%;
}
.article-part1 div {
    padding: 30px;
}
.article-part2 div {
    padding: 30px;
}
.logo-description {
    margin-right: 30px;
    justify-content: flex-start;
}
.logo {
    width: 250px;
    margin-bottom: 30px;
}
.logo1 {
    width: 250px;
   /* margin-bottom: -20px;*/
}
.choose-us {
    font-size: 20px;
    font-weight: 500;
    margin:20px 0 0 0;
}
.hight-carusel-conteiner {
    width: 600px;
}
.sentence-container img {
    width: 230px;
}
.sentence {
    font-size: 23px;
}
.article-container {
    margin:0;
    width: 600px;
}
.article {
    width: 600px;
    margin: 0 auto;
    padding: 70px 0;
}
.signature-container {
    width: 100%;
    padding: 0;
    margin:0;
}
.article-part1 div {
    margin: 0;
    padding: 0;
}
.article-part2 {
    width: 600px;
}
.header1 .logo-description {
    display: none;
}
.second-container-background {
    margin:0 0 -25px 0;
    padding: 0;
    height: 1100px;
    display: flex;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 1000px;
    background-position: bottom 0 left 0;
    z-index: 100;
}
}
@media only screen and (max-width: 600px) {
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    flex-direction: column;
    margin: 30px auto;
    padding: 5px;
    box-sizing: border-box;
}
.header1 {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}
.article-part1 {
    margin: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
}
.signature-container {
    width:100%;
    margin: 0;
    padding: 0;
}
.article-part1 div {
    padding: 10px;
}
.article-part2 div {
    padding: 10px;
}
.logo-description {
    width: 100%;
    box-sizing: border-box;
    margin-right: 0;
    padding-bottom: 30px;
    justify-content: center;
    align-items: center;
}
.logo {
    width: 150px;
    margin-bottom: 10px;
}
.logo1 {
    width: 200px;
   /* margin-bottom: -20px;*/
}
.choose-us {
    font-size: 18px;
    font-weight: 500;
    margin:0 auto;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.choose-us p {
    text-align: center;
}
.choose-us ul li {
    text-align: center;
}
ul {
    padding-inline-start: 0px;
}
.about-carusel-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5px;
}

.hight-carusel-conteiner {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items:center;
}
.sentence-container img {
    width: 140px;
    margin: 0 5px;
}
.sentence {
    font-size: 16px;
    margin-top: 3%;
}
.sentence1-container p{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0;
    text-align: center;
    font-size: 20px;
}
.article-container {
    margin:0;
    width: 100%;
    box-sizing: border-box;
}
.article {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 70px 0;
}
.article-part2{
    margin: 5px;
    width: 100%;
}
.article-part1 {
    margin: 5px;
}
.header1 .logo-description {
    display: none;
}
.second-container-background {
    margin:0 0 -25px 0;
    padding: 0;
    height: 1380px;
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 600px;
    background-position: bottom 0 left 0;
    z-index: 100;
}
}
</style>