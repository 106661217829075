<template>
    <div class="main-header">
        <div class="header" :style="{ backgroundImage: 'url(' + images.healthyFood + ')' }" id="header-healthy-food">
        </div>
        <div class="first-paragraph">
            <p class="sentence">Niezależnie od tego, kto był ojcem choroby, zła dieta była na pewno jej matką</p>
            <p>To chińskie przysłowie przyświeca treściom zawartym w części nazwanej „Zdrowe odżywianie” Portalu Mendel Helps Life.
            Pragniemy wszystkich uspokoić, iż nie jest naszą ambicją i powołaniem, aby cokolwiek i komukolwiek nakazywać,
            aby do czegokolwiek namawiać oraz aby wreszcie w czymkolwiek kogokolwiek zastępować.
            Chcemy i będziemy dla niektórych z Państwa pisać rzeczy oczywiste,
            a dla niektórych odkrywać nowe światy związane z szeroko rozumianym zdrowym odżywianiem. </p>
        </div>
        <div class="second-paragraph">
            <div class="checked-info">
                <h4>Sprawdzone informacje</h4>
                <p>Nasze informacje, zawsze starannie wyselekcjonowane i w maksymalnie możliwy sposób sprawdzone,
                opierać się będą na wiedzy, której źródła sięgają czasów starożytnych, poprzez okres średniowiecza,
                na tej, na nowo odkrytej i/lub zbadanej stosunkowo niedawno, współcześnie kończąc.
                Mając na uwadze hasło przyświecające naszej działalności,
                zapraszamy wszystkich Państwa do ekscytującej przygody, wspólnej podróży w kierunku ugruntowania
                i poznania wpływu zdrowego, codziennego odżywiania na nasze zdrowie. </p>
                <a href="#healhyProducts"><button class="green-button">Co jeść</button></a>
            </div>
            <img :src="images.laboratory" alt="laboratory" class="food-image"/>
        </div>
        <div class="second-paragraph1">
            <img :src="images.laboratory1" alt="laboratory" class="food-image"/>
            <div class="checked-prod">
                <h4>Sprawdzeni Partnerzy</h4>
                <p>Podróż będzie o tyle łatwiejsza, że w jej trakcie będziecie Państwo mogli poznać producentów,
                wytwórców i posilić się zakupionymi u nich zdrowymi produktami, a gdy już będziecie trochę zmęczeni
                wypocząć lub podreperować zdrowie w miejscach przez nas wskazanych. Z ogromnym entuzjazmem wsiadamy
                do pociągu <strong>Mendel Helps Life</strong> z nadzieją, że nie będziecie się Państwo w czasie tej podróży nudzić
                i będziemy wędrować razem już zawsze, a po dotarciu do jednej stacji, z upływem czasu będziemy
                sobie wyznaczać wspólnie kolejne, w co wierzę i do czego już dzisiaj Państwa namawiam. </p>
                <a href="/#searcher"><button class="green-button">Znajdź ofertę</button></a>
            </div>
        </div>
        <div class="vegetables">
            <div id="healhyProducts">
                <h4 class="sentence2">Jemy, aby zdrowo żyć …</h4>
                <p>SPRAWDZONE PRODUKTY NAJLEPSZĄ INWESTYCJĄ W ZDROWIE</p>
                <p>Poniżej przedstawiamy wybrane produkty, ich oddziaływanie na organizm oraz przepisy kulinarne</p>
            </div>
            <div class="vegetables-container">
                <div v-for="vegetable in vegetables" class="vegetable-box" :key="vegetable.id">
                    <img :src="'/photos/' + vegetable.img" alt="pic" @click="showVegetable(vegetable.id)">
                    <h3 @click="showVegetable(vegetable.id)">{{vegetable.name}}</h3>
                </div>
            </div>
            <ChoosenVegetable v-if="choosenVegetableBoxisVisible" :vege="choosenVegetable" @closeWin="closeChoosenVege"/>
        </div>
    </div>
</template>
<script>
import ChoosenVegetable from './ChoosenVegetable.vue';
import vegetables from '../vegetables.js'
import healthyFood from '../photos/healthyFood_background1.jpeg';
import laboratory from '../photos/laboratory3.png';
import laboratory1 from '../photos/laboratory2.png';
import broccoli from '../photos/broccoli.png';
import garlic from '../photos/czosnek.jpg';
export default {
    components: {
        ChoosenVegetable
    },
    data() {
            return {
                images: {
                    healthyFood: healthyFood,
                    laboratory: laboratory,
                    laboratory1: laboratory1,
                    broccoli: broccoli,
                    garlic: garlic
                },
                vegetables: vegetables,
                choosenVegetable:[],
                choosenVegetableBoxisVisible: false
            }
        },
        methods: {
             getImgUrl(vegImg) {
                return require('../photos/'+ vegImg);
            },
            showVegetable(id) {
                let choosenVege = vegetables.find((vege) => vege.id == id)
                this.choosenVegetable = choosenVege;
                this.choosenVegetableBoxisVisible = true;
                console.log(this.choosenVegetable);
            },
            closeChoosenVege() {
                this.choosenVegetableBoxisVisible = false;
            }
        }
}
</script>
<style scoped>
    #header-healthy-food {
        height: 350px;
        width: 100vw;
        background-size: cover;
        background-position: center;
        margin-bottom: 80px;
        margin-top: -30px;
    }
    .sentence {
        font-family: "Caveat", cursive;
        font-size: 45px;
        margin-bottom: 10px;
        font-weight: 600;
        color: #3084E2;
    }
    .sentence2 {
        font-size: 60px;
        font-family: "Caveat", cursive;
        color: #3084E2;
        margin: 0;
    }
    .first-paragraph {
        width: 1450px;
        margin: 0 auto;
        line-height: 25px;
    }
    .second-paragraph {
        width: 1450px;
        margin: 100px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .second-paragraph1 {
        width: 1450px;
        margin: 100px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .food-image {
        width: 50%;
    }
    .checked-info {
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:flex-start;
        margin-right: 30px;
    }
    .checked-prod {
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:flex-end;
        margin-left: 30px;
    }
    .checked-info h4, .checked-prod h4 {
        font-size: 40px;
        margin: 0;
        color: #3084E2;
        font-weight: 600;
    }
    .checked-info p {
        text-align: left;
        line-height: 25px;
    }
    .checked-prod p {
        text-align: right;
        line-height: 25px;
    }
    .wisdom {
        margin: 0;
    }
    button {
    padding: 13px 65px;
    border-radius: 25px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 0px;
    color: white;
}

    button:hover {
    background-color: #3084E2;
    cursor: pointer;
}
.vegetables {
    margin: 80px auto;
}
.vegetables p {
    font-weight: 500;
}
.vegetables-container {
    margin: 80px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.vegetable-box {
    padding: 15px;
    margin: 20px;
    border-radius: 20px;
    transition: 0.3s ease-out;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
}
.vegetable-box:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.vegetable-box:hover p {
    cursor: pointer;
}
.vegetable-box img {
    width: 200px;
}
.vegetable-box h3 {
    margin: 10px 0 5px 0;
    cursor: pointer;
}
@media only screen and (min-width: 1201px) and (max-width: 1450px) {
    #header-healthy-food {
        margin-bottom: 50px;
    }
    .sentence {
        font-family: "Caveat", cursive;
        font-size: 45px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .sentence2 {
        font-size: 50px;
        font-family: "Caveat", cursive;
        margin: 0;
    }
    .first-paragraph {
        width: 1200px;
        margin: 0 auto;
        line-height: 25px;
    }
    .second-paragraph {
        width: 1200px;
        margin: 100px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    } .second-paragraph1 {
        width: 1200px;
        margin: 100px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .food-image {
        width: 50%;
    }
    .checked-info h4, .checked-prod h4 {
        font-size: 40px;
        margin: 0;
    }
    .checked-info p {
        text-align: left;
        line-height: 25px;
    }
    .checked-prod p {
        text-align: right;
        line-height: 25px;
    }
    .wisdom {
        margin: 0;
    }

    button:hover {
    background-color: #3084E2;
    cursor: pointer;
}
.vegetables {
    margin: 80px auto;
}
.vegetables p {
    font-weight: 500;
}
.vegetables-container {
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.vegetable-box {
    padding: 15px;
    margin: 15px;
}
.vegetable-box:hover p {
    cursor: pointer;
}
.vegetable-box img {
    width: 200px;
}
.vegetable-box h3 {
    margin: 10px 0 5px 0;
}
}
@media only screen and (min-width: 901px) and (max-width: 1200px) {

    #header-healthy-food {
        margin-bottom: 50px;
    }
    .sentence {
        font-family: "Caveat", cursive;
        font-size: 40px;
        margin-bottom: 10px;
        line-height: 1;
        font-weight: 600;
    }
    .sentence2 {
        font-size: 50px;
        font-family: "Caveat", cursive;
        margin: 0;
    }
    .first-paragraph {
        width: 900px;
        margin: 0 auto;
        line-height: 25px;
    }
    .second-paragraph {
        width: 900px;
        margin: 100px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .second-paragraph1 {
        width: 900px;
        margin: 100px auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .food-image {
        width: 50%;
    }
    .checked-info {
        margin-right: 30px;
    }
    .checked-prod {
        margin-left: 30px;
    }
    .checked-info h4 {
        font-size: 35px;
        text-align: left;
        margin: 0;
    }
    .checked-prod h4 {
       font-size: 35px;
        text-align: right;
        margin: 0;
    }
    .checked-info p {
        text-align: left;
        line-height: 25px;
    }
    .checked-prod p {
        text-align: right;
        line-height: 25px;
    }
    .wisdom {
        margin: 0;
    }

    button:hover {
    background-color: #3084E2;
    cursor: pointer;
}
.vegetables {
    margin: 80px auto;
}
.vegetables p {
    font-weight: 500;
}
.vegetables-container {
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.vegetable-box {
    padding: 15px;
    margin: 15px;
}
.vegetable-box:hover p {
    cursor: pointer;
}
.vegetable-box img {
    width: 200px;
}
.vegetable-box h3 {
    margin: 10px 0 5px 0;
}
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
    #header-healthy-food {
        margin-bottom: 50px;
    }
    .sentence {
        font-family: "Caveat", cursive;
        font-size: 35px;
        margin-bottom: 10px;
        line-height: 1;
        font-weight: 600;
    }
    .sentence2 {
        font-size: 40px;
        font-family: "Caveat", cursive;
        margin: 0;
    }
    .first-paragraph {
        width: 600px;
        margin: 0 auto;
        line-height: 25px;
        font-size: 17px;
        text-align: justify;
    }
    .second-paragraph1 {
        width: 600px;
        margin: 70px auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .second-paragraph {
        width: 600px;
        margin: 70px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .food-image {
        width: 50%;
    }
    .checked-info {
        margin-right: 5px;
        margin-bottom:30px;
    }
    .checked-prod {
        margin-left: 5px;
        margin-bottom:30px;
    }
    .checked-info h4 {
        font-size: 30px;
        text-align: right;
        margin: 0;
    }
    .checked-prod h4 {
       font-size: 30px;
        text-align: left;
        margin: 0;
    }
    .checked-info p {
        text-align: left;
        line-height: 22px;
        font-size: 16px;
        text-align: justify;

    }
    .checked-prod p {
        text-align: left;
        line-height: 22px;
        font-size: 16px;
        text-align: justify;
    }
    .wisdom {
        margin: 0;
    }

    button:hover {
    background-color: #3084E2;
    cursor: pointer;
}
.vegetables {
    margin: 80px auto;
    width: 600px;
}
.vegetables p {
    font-weight: 500;
}
.vegetables-container {
    margin: 30px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.vegetable-box {
    padding: 15px;
    margin: 15px;
}
.vegetable-box:hover p {
    cursor: pointer;
}
.vegetable-box img {
    width: 150px;
}
.vegetable-box h3 {
    margin: 10px 0 5px 0;
}
}
@media only screen and (max-width: 600px) {
    .main-header {
        width: 100%;
        margin: 0 auto;
    }
    #header-healthy-food {
        margin-bottom: 20px;
        width: 100%;
        height: 250px;
    }
    p.sentence {
        width: 100%;
        font-family: "Caveat", cursive;
        font-size: 30px;
        text-align: center;
        margin: 10px 0 10px 0;
        line-height: 1;
        font-weight: 600;
    }
    .sentence2 {
        font-size: 40px;
        font-family: "Caveat", cursive;
        margin: 0;
        width: 100%;
    }
    .first-paragraph {
        box-sizing: border-box;
        width:100%;
        padding: 10px;
        line-height: 22px;
        font-size: 17px;
        text-align: justify;
        margin:0 auto;
    }
    .first-paragraph p {
    }
    .second-paragraph {
        box-sizing: border-box;
        width: 100%;
        margin: 40px auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
        .second-paragraph1 {
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        margin: 40px auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .food-image {
        width: 80%;
        margin: 30px;
    }
    .checked-info {
        width:100%;
        box-sizing: border-box;
        padding: 10px;
        margin-right: 5px;
        align-items: flex-start;
    }
    .checked-prod {
        margin-left: 5px;
        margin-bottom:20px;
        align-items: flex-start;
    }
    .checked-info h4 {
        font-size: 25px;
        align-self: flex-start;
        text-align: left;
        margin: 0;
    }
    .checked-prod h4 {
       font-size: 25px;
        text-align: left;
        margin: 0;
    }
    .checked-info p {
        text-align: left;
        line-height: 22px;
        font-size: 16px;
        text-align: justify;

    }
    .checked-prod p {
        text-align: left;
        line-height: 22px;
        font-size: 16px;
        text-align: justify;
    }
    .wisdom {
        margin: 0;
        align-self: flex-start;
        font-size: 17px;
    }

    button:hover {
    background-color: #3084E2;
    cursor: pointer;
}
.vegetables {
    margin: 0px auto;
    width: 100%;
}
.healhyProducts {
    width: 100%;
    padding: 10px;
}
.healhyProducts p{
    width: 100%;
}
.vegetables p {
    font-weight: 500;
}
.vegetables-container {
    margin: 30px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.vegetable-box {
    padding: 15px;
    margin: 15px;
    height: 150px;
    width: 120px;
}
.vegetable-box:hover p {
    cursor: pointer;
}
.vegetable-box img {
    width: 100px;
}
.vegetable-box h3 {
    margin: 10px 0 5px 0;
    font-size: 15px;
    word-wrap: wrap;
}

}
</style>