<template>
<div>
    <div class="desc-container" :style="{ backgroundImage: 'url(' + images.heatlhyFood + ')' }">
      <div class="map-desc-container">
        <div class="description">
          <div class="right-desc">
              <h2>Produkty od sprawdzonych wytwórców i producentów.</h2>
              <p>Produkty, które spożywamy i których używamy to jak się zdaje jeden z ostatnich elementów naszej egzystencji, na który możemy i mamy bezpośredni wpływ. Bo przecież to my, parafrazując słowa założyciela i dyrektora Arizona Center for Integrative Medicine, decydujemy ostatecznie, co dostanie się do naszych ust i żołądka, czego używamy celem poprawy zdrowia i pielęgnacji naszego ciała.</p>
          </div>
        </div>
            <div class="map-cont">
            <div @click="goToMap" class="go-to-map-container">
              <img :src="images.Poland" alt="mapa Polski"/>
              <button class="find-smth">Znajdź produkt</button>
          </div>
        </div>
      </div>
    </div>
  <div class="second-desc-container">
      <div class="column-left">
        <div class="column-left-content">
            <p class="desc1">Nie tylko piszemy o dobrych produktach</p>
            <p class="bigger">Ambicją naszego Portalu </p>
            <p class="desc3">jest prezentacja pełnej oferty produktowej o ponadprzeciętnej jakości</p>
            <p class="desc4">bezpośrednio u naszych Partnerów - rolników, wytwórców, producentów</p>
            <p class="italic">Zapraszamy i życzymy wspaniałych wrażeń. </p>
        </div>
      </div>
  </div>
  <div class="products-container">
    <div>
      <p class="map-desc">
        Wybierz województwo, a następnie wpisz kategorię której szukasz.
      </p>
    </div>
    <div class="map-cont1">
      <PolandMap 
        @clickWoj="clickWoj"
      />
    </div>
    <div class="{active: hover}" id="tooltip1">{{hoverWoj}}</div>
  <!--  <div>{{ choosenCompany.nazwa_producenta }}</div>-->
    <div id="filters"></div>
    <div v-if="clickedWoj !== ''" class="filters">
      <div class="filter">
        <label>Kategoria</label>
        <select name="category" id="category" v-model="choosenCategory">
          <option value="" disabled>Wybierz kategorię</option>
          <option
            v-for="category in allCategories"
            v-bind:key="category"
            :value="category.name"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
      <div class="filter">
        <label>Podkategoria</label>
        <select
          name="subcategory"
          id="subcategory"
          v-model="choosenSubcategory"
        >
          <option value="" disabled>Wybierz podkategorię</option>
          <option v-for="type in typesCategory" :key="type">{{ type }}</option>
        </select>
      </div>
      <div class="filter-button-container">
        <button @click="filter" class="filter-button">Filtruj</button>
      </div>
    <p v-if="alcoholAlertIsVisible" class="alkohol-alert">*Zapraszamy jeśli masz ukończone 18 lat.</p>
    </div>
    <div v-if="isShownChoosenCompany" class="choosen-company">
      <div class="background"></div>
      <div class="choosen-company-box">
      <img :src="images.cross" class="cross" @click="closeWindow"/>
        <div class="column-container">
          <div class="right-column">
            <div class="icons">
              <div>
                <div class="icon-box">
                  <img :src="images.location" alt="location" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_producenta }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.phone" alt="phone number" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.tel1 }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.mail" alt="mail" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_mailowy }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.website" alt="website" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_strony }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.facebook" alt="facebook" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.facebook }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.insta" alt="instagram" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.instagram }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-column">
            <div class="contact-and-name">
              <div class="logo-box">
                <img :src="choosenCompany.logo" class="logo" />
              </div>
              <div class="prod-name">{{ choosenCompany.nazwa_producenta }}</div>
            </div>
          </div>
        </div>
      <div class="description-choosen-company">
        <p class="company-desc">
          <span class="bolder-comp-desc">Tu będzie opis firmy i/lub działalności i/lub produktu zgodnie z Twoją decyzją.</span> Lorem Ipsum is simply dummy text of the printing
          and typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived
          not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in
          the 1960s with the release of Letraset sheets containing Lorem Ipsum
          passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum.
        </p>
      </div>
      </div>
    </div>
    <div class="boxes-container" v-if="companiesAreVisible">
    <!-- PRODUCTS CARUSEL -->
    <div class="products-carusel-above-container">
    <div class="products-carusel-conteiner">
        <!--<Flicking :options="{ circular: true, stopOnHover: true, preventClickOnDrag: false}" :plugins="pluginsProducts">-->
            <div class="company-box2">
              <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 1</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Radosna 56, 99-999 Jawor</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
                <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 2</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Miła 45, 99-999 Kłodzko</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 2','Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img :src="images.imgLogo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div class="be-partner">
            <p>Partnerzy</p>
          </div>
          <div>
            <img :src="images.imgLogoHelps" class="logo-small1" alt="Twoje logo"/>
          </div>
        </div>
        <div class="select">
          <router-link to="/bePartner"><button>Zostań Partnerem</button></router-link>
        </div>
      </div>
        <!--</Flicking>-->
      </div>
          <div class="products-carusel-conteiner1">
        <!--<Flicking :options="{ circular: true, stopOnHover: true, preventClickOnDrag: false}" :plugins="pluginsProducts">-->
            <div class="company-box2">
              <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 1</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Radosna 56, 99-999 Jawor</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
                <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 2</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Miła 45, 99-999 Kłodzko</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 2','Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img :src="images.imgLogo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div class="be-partner">
            <p>Partnerzy</p>
          </div>
          <div>
            <img :src="images.imgLogoHelps" class="logo-small1" alt="Twoje logo"/>
          </div>
        </div>
        <div class="select">
          <router-link to="/bePartner"><button>Zostań Partnerem</button></router-link>
        </div>
      </div>
        <!--</Flicking>-->
      </div>
    </div>
    <!--END PRODUCTS CARUSEL -->
        <!--Test companies -->
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5>Imię i Nazwisko TEST 5</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
    <!--Test companies -->
      <div class="company-box1">
        <div class="box-first-part">
          <div>
            <img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5>Nazwa Firmy TEST 1</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
      <div class="box-first-part">
        <div>
          <img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/>
        </div>
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
        <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych')">Wybierz</button>
        </div>
      </div>
       <div class="company-box1">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 5</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
         <!--Test companies -->
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 1</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
      <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych')">Wybierz</button>
        </div>
      </div>
       <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5 :class="{fontAndSite: true}">Imię i Nazwisko TEST 5</h5>
          </div>
          <div v-if=true class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
     <!--COMPANIES FIRST PLACE-->
      <div
        v-for="company in companiesFirstPlace"
        v-bind:key="company.id"
        class="company-box"
      >
        <div class="box-first-part">
          <div v-if="company.wyrLogo=='1'">
            <img :src="company.logo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5 :class="{fontAndSite: company.wyrCzcionka=='1'}">{{ company.nazwa_producenta }}</h5>
          </div>
          <div v-if="company.wyrCzcionka=='1'" class="website-container">
            <p class="website">{{ company.adres_strony}}</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>{{ company.adres_producenta }}</p>
          </div>
          <!--<div><img :src="company.logo" class="logo"/></div>-->
        </div>
        <div class="select">
          <button @click="showCompany(company.id)">Wybierz</button>
        </div>
      </div>
      <!--END COMPANIES FIRST PLACE -->
      <div
        v-for="company in wojCompanies"
        v-bind:key="company.id"
        class="company-box"
      >
        <div class="box-first-part">
          <div v-if="company.wyrLogo=='1'">
            <img :src="company.logo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5 :class="{fontAndSite: company.wyrCzcionka=='1'}">{{ company.nazwa_producenta }}</h5>
          </div>
          <div v-if="company.wyrCzcionka=='1'" class="website-container">
            <p class="website">{{ company.adres_strony}}</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>{{ company.adres_producenta }}</p>
          </div>
          <!--<div><img :src="company.logo" class="logo"/></div>-->
        </div>
        <div class="select">
          <button @click="showCompany(company.id)">Wybierz</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import CompaniesDataService from "../GetCompanies.js";
import categories from "../categories.js";
//import { ref } from 'vue'
import axios from "axios";
import location from "../photos/home.png";
import phone from "../photos/phone.png";
import mail from "../photos/mail.png";
import website from "../photos/website.png";
import facebook from "../photos/facebook.png";
import insta from "../photos/insta.png";
import certyfikat from "../photos/certyfikat.png";
import cross from "../photos/cross.png";
import heatlhyFood from "../photos/zdrowe_odzywianie12.jpeg";
import leaf from "../photos/leaf.png";
import Poland from "../photos/poland.png";
import imgLogo from '../photos/Mendel_Helps_logo_male.png';
import imgLogoHelps from '../photos/Mendel_Helps_logo.png';
//import Flicking from "@egjs/vue3-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import PolandMap from './PolandMap.vue';

export default {
  name: "TheCompanies",
  components: {
    PolandMap
  },
  data() {
    return {
      pluginsProducts: [new AutoPlay({ duration: 500, animationDuration: 6000, direction: "NEXT", stopOnHover: true})],
      companies: [],
      companiesFirstPlace:[],
      message: "",
      clickedWoj: "",
      clickedWojId: "",
      hoverWoj: "",
      wojCompanies: [],
      photos: [],
      isShownChoosenCompany: false,
      choosenCompany: [],
      allCategories: categories,
      choosenCategory: "",
      alcoholAlertIsVisible: false,
      choosenSubcategory: "",
      typesCategory: [],
      companiesAreVisible: false,
      hover3: true,
      images: {
        location: location,
        phone: phone,
        mail: mail,
        website: website,
        facebook: facebook,
        insta: insta,
        certyfikat: certyfikat,
        cross: cross,
        heatlhyFood: heatlhyFood,
        leaf: leaf,
        Poland: Poland,
        imgLogo:imgLogo,
        imgLogoHelps: imgLogoHelps
      },
    };
  },
  watch: {
    choosenCategory() {
      let category = this.choosenCategory;
      if (category !== "") {
        let typesArray = this.allCategories.find(
          (option) => option.name == category
        );
        this.typesCategory = typesArray.types;
        this.choosenSubcategory = "";
        if(category == "napoje alkoholowe") {
          this.alcoholAlertIsVisible = true;
        } else {
           this.alcoholAlertIsVisible = false;
        }
      }
    },
  },
  methods: {
    refreshUsers() {
      CompaniesDataService.retrieveAllCompanies().then((res) => {
        this.companies = res.data;
        console.log(this.companies);
      });
    },
    clickWoj(woj, id) {
      this.companiesAreVisible = false;
      var allMap = document.getElementsByClassName('woj');
      var index = 0, length = allMap.length;
      for ( ; index < length; index++) {
        allMap[index].style.fill = "#6d8b33";
    }
      this.clickedWojId = id;
      var clickedMapPart = document.getElementById(this.clickedWojId);
      clickedMapPart.style.fill = '#d2ca61';
      this.clickedWoj = woj;
      //var newTable = this.companies.filter((el) => el.województwo == woj);
      //console.log(newTable);
      //this.wojCompanies = newTable;
      this.companiesAreVisible = true;
      console.log(this.companiesAreVisible);
    },
    filter() {
      console.log(this.choosenCategory);
      console.log(this.choosenSubcategory);
      var newTable = this.companies.filter(this.filterValues);
      console.log(newTable);
      var compFirstPlace = newTable.filter((el)=> el.wyrPierwszeMiesca == '1');
      this.companiesFirstPlace = compFirstPlace;
      console.log(compFirstPlace);
      var restCompanies = newTable.filter((el)=> el.wyrPierwszeMiesca == '0');
      this.wojCompanies = restCompanies;
      console.log(this.wojCompanies);
      this.companiesAreVisible = true;
      this.choosenCategory = '';
      this.choosenSubcategory = '';
    },
    filterValues(el) {
      if (this.choosenCategory == "" && this.choosenSubcategory == "") {
        console.log(this.clickedWoj);
        return el.województwo == this.clickedWoj;
      } else if (this.choosenCategory != "" && this.choosenSubcategory == "") {
        return (
          el.category == this.choosenCategory &&
          el.województwo == this.clickedWoj
        );
      } else if (this.choosenCategory != "" && this.choosenSubcategory != "") {
        return (
          el.category == this.choosenCategory && el.subcat.includes(this.choosenSubcategory) && el.województwo == this.clickedWoj
        );
      } else {
        return el;
      }
    },
    showCompany(id) {
      var choosenComp = this.companies.filter((el) => el.id == id);
      this.choosenCompany = choosenComp[0];
      console.log(this.choosenCompany);
      console.log(this.choosenCompany.nazwa_producenta);
      console.log(this.choosenCompany.adres_mailowy);
      this.isShownChoosenCompany = true;
    },
    showTestCompany(nazwa, adres, logoUrl) {
      var choosenCompTest = {
       adres_mailowy: "twojmail@gmail.com",
       adres_producenta: adres,
       adres_strony: "www.twojastrona.pl",
       category: "TEST",
       facebook: "https://twojfacebook.pl",
       id: "39",
       instagram: "https://twojistagram.pl",
       logo: logoUrl,
       nazwa_producenta: nazwa,
       opis: "Tu będzie opis Twojej firmy. \r\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
       subcat: "TEST",
       tel1: "999 999 999",
       tel2: "99 999 99 99",
       tel3: "999 999 999",
       województwo: "dolnośląskie"
    }
    this.choosenCompany = choosenCompTest;
    this.isShownChoosenCompany = true;
    },
    closeWindow() {
      this.isShownChoosenCompany = false;
    },
    runningTooltip(e) {
                var tooltipSpan = document.getElementById('tooltip1');
                var x = e.clientX,
                    y = e.clientY;
                var isMobile = /Mobi|Android/i.test(navigator.userAgent);

                if (isMobile) {
                    tooltipSpan.style.top = (y + 450) + 'px';
                    tooltipSpan.style.left = (x + 20) + 'px';
                } else {
                    tooltipSpan.style.top = (y + 20) + 'px';
                    tooltipSpan.style.left = (x + 20) + 'px';
                }
    },
    showState2(state) {
      this.hover3 = true;
      this.hoverWoj = state;
          var tooltipSpan = document.getElementById('tooltip1');
          tooltipSpan.style.display = 'block';
          //function runningTooltip(e) {
          //   var tooltipSpan = document.getElementById('tooltip1');
          //       var x = e.clientX,
          //           y = e.clientY;
          //       tooltipSpan.style.top = (y + 20) + 'px';
          //       tooltipSpan.style.left = (x + 20) + 'px';
          // }
             // window.addEventListener("onmousemove", this.runningTooltip());

          //   if(this.hover3 == true) {
          //     window.onmousemove = function (e) {
          //       var tooltipSpan = document.getElementById('tooltip1');
          //       var x = e.clientX,
          //           y = e.clientY;
          //       tooltipSpan.style.top = (y + 20) + 'px';
          //       tooltipSpan.style.left = (x + 20) + 'px';
          //   };
          // }

    },
    hideWojText() {
    var tooltipSpan = document.getElementById('tooltip1');
      tooltipSpan.style.display = 'none';
      this.hover3 = false;
      console.log(this.hover3);

        //var map = document.getElementById('map');
        //map.onmouseleave = function () {
        // var tooltipSpan = document.getElementById('tooltip');
        // console.log(tooltipSpan);
        // tooltipSpan.style.display = 'none';
        //}
    },
    goToMap() {
      const map = document.getElementById("map");
      map.scrollIntoView();
    }
  },
//  unmounted() {
//     window.removeEventListener("onmousemove", this.runningTooltip());
//   },
  created() {
    axios
      .get(`./fetch.php`)
      .then((response) => response)
      .then((res) => {
        this.companies = res.data;
        console.log("companies");
        console.log(this.companies);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.log("server is down!!");
      });
        //       var map = document.getElementById('map');
        // map.onmouseleave = function () {
        // var tooltipSpan = document.getElementById('tooltip');
        // console.log(tooltipSpan);
        // tooltipSpan.style.display = 'none';
        // }
  },
};
</script>

<style scoped>
.desc-container {
    height: 350px;
    background-size: cover;
    background-position: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0;
    padding-bottom: 0;
}
.map-desc-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    width: 1450px;
}
.desc-container div {
  color: white;
}
.map-cont {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start;
}
.desc-container img {
  width: 300px;
  margin-bottom: 15px;
  margin-left: 0px;
  transition: 0.3s ease-out;
}
.desc-container img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:40px;
  width: 60%;
  text-align: center;
  margin-left: 0;
  self-align: center;
  margin-bottom: 0;
}
.right-desc {
  width: 600px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right-desc p {
  text-align: justify;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc3 {
  line-height: 22px;
  font-size: 20px;
}
.desc4 {
  font-size: 22px;
  font-weight: 600;
}
.description h2 {
  padding: 0;
  margin: 0 auto;
  color: white;
  font-size: 23px;
  text-align: center;
}
.description p {
  padding: 20px 0;
  margin:0;
  font-size: 17px;
  color: white;
  line-height: 22px;
}
.description p span {
  font-weight: bolder;
}
.second-desc-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.second-desc-container p {
  text-align: center;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1450px;
  margin: 30px auto;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 1450px;
}
.bigger {
    font-size: 30px;
    margin: 15px 0;
    color: #3084E2;
    font-weight: 700;
}
.go-to-map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  position: relative;
}
.find-smth {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 5px 25px;
  margin: 5px auto 10px auto;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:110px;
  left: 40px;
}
.find-smth:hover {
  font-weight: 600;
  color: #3084E2;
}
.italic {
  color: #3084E2;
  font-style: italic;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  margin-top: 35px;
  }
.products-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}
.map {
  margin: 30px 0;
}
path {
  fill: #6d8b33;
}
path:hover {
  fill: #d2ca61;
  cursor: pointer;
}
.map-desc {
  margin-top: 50px;
  font-size: 19px;
  font-weight: 600;
}
svg text {
  display: none;
  z-index: 10;
}
svg path:hover text {
  display: block;
  z-index: 10;
}
.filter {
  display: inline-block;
}
.filter label {
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
}
.filter-button-container {
  display: inline-block;
}
.filter-button {
  display: inline-block;
  height: 35px;
  font-size: 17px;
  padding: 8px 30px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.filter-button:hover {
  cursor: pointer;
  background-color: #75b1f5;
}
select {
  height: 30px;
  padding: 3px;
  font-size: 17px;
  line-height: 30px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.filters {
  margin: 50px;
}
.products-carusel-above-container {
  width: 1400px;
  overflow:hidden;
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.products-carusel-above-container:hover > .products-carusel-conteiner {
  animation-play-state: paused;
}
.products-carusel-above-container:hover > .products-carusel-conteiner1 {
  animation-play-state: paused;
}
.products-carusel-conteiner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  //animation: 10s ease-in 1s infinite reverse both running carusel;
  animation: 10s linear 0s infinite running carusel;

}
 .products-carusel-conteiner1 {
    display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  //animation: 10s ease-in 1s infinite reverse both running carusel;
  animation: 10s linear 0s infinite running carusel;
 }
@keyframes carusel {
    from {
      display: flex;
      transform: translateX(-50%);
    }

    to {
      display: flex;
      transform: translateX(50%);
    }
  }

.company-box, .company-box1 {
  width: 250px;
  height: 330px;
  margin: 20px;
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
  box-sizing: border-box;
}
.company-box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 330px;
  margin: 20px;
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
  box-sizing: border-box;
}
.box-first-part {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 87%;
}
.company-box h5, .company-box1 h5, .company-box2 h5 {
  margin: 10px 0 20px 0;
}
.company-box h5 {
  font-weight: 400;
  font-size: 19px;
}
.company-box1 h5 {
  font-weight: 700;
  font-size: 19px;
}
.company-box2 h5 {
  font-weight: 700;
  font-size: 19px;
}

/*styling font-size to bolder distinctions*/
.fontAndSite {
  font-weight: 700!important;
  font-size: 19px;
}

.website-container {
  margin: 0;
  padding: 0;
}
.website {
  margin: 0;
  padding: 0;
  font-style: italic;
}
.boxes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  aling-items: center;
  justify-content: center;
  margin: 50px;
  width: 1450px;
}
button {
  margin: 0;
  padding: 15px;
  color: white;
  border-radius: 20px;
  background-color: #5d4db7;
  box-sizing: border-box;
}
.select {
  width: 100%;
  align-self: end;
}
.select button {
  width: 100%;
  align-self: flex-end;
  border: none;
  padding: 10px;
  font-size: 17px;
  border-radius: 20px;
  background-color: #3084e2;
}
.select button:hover {
  background-color: #5e97d5;
}
.logo {
  width: 150px;
}
.logo-small {
  width: 100px;
  margin: 0;
  padding: 0;
}
.logo-small1 {
  width: 180px;
  margin-top:0;
  padding-top:0;
}
.be-partner {
  padding-bottom: 0;
  margin-bottom: 5px;
}
.be-partner p{
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 19px;
  font-family: "Comfortaa", sans-serif;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.choosen-company-box {
  position: fixed;
  top: 8vh;
  left: 20%;
  width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 12px;
  border: 5px solid #504DB7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 0px;
  margin: 60px;
}
.column-container {
  position: relative;
  margin: 30px 30px 0 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.left-column,
right-column {
  margin: 20px;
  display: inline-block;
  height: 100%
}
.logo {
  width: 150px;
  margin-bottom: 30px;
}
.prod-name {
  font-size: 35px;
  color: #504DB7;
  margin-top: 30px;
  font-weight: 500;
}
.prod-adres {
  font-size: 17px;
}
.contact-data {
  background-color: #3084e2;
  font-size: 17px;
  padding: 12px 20px;
  color: white;
  margin: 20px;
  clear: both;
}
.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icons img {
  width: 40px;
  margin-right: 10px;
}
.icon-box {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  clear: both;
}
.bolder-comp-desc {
  font-weight: bolder;
}
.icons p {
  font-weight: bold;
  margin: 3px;
  text-align: left;
  float: left;
}
.icons h5 {
  margin: 3px;
  font-weight: 400;
  font-size: 16px;
}
.contact-and-name {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}
.company-desc {
  padding: 30px;
  text-align: justify;
}
.description {
  clear: both;
  padding: 0 30px 30px 30px;
}
.icon-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  aling-items: flex-start;
}
.cross {
  width: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.woj-text {
  font-size: 30px;
  fill: black;
}
#tooltip1 {
    opacity: 1;
    display:none;
    position: fixed;
    color: #3084E2;
    font-weight: 500;
    background-color: rgb(240,248,255,0.9);
    padding: 10px 15px;
    border-radius: 15px;
}
.active {
  display: block;
  color: red;
}
.alkohol-alert {
  color:red;
}
@media only screen and (min-width: 900px) and (max-width: 1250px) {
.map-desc-container {
    width: 900px;
}
.desc-container div {
  color: white;
}
.map-cont {
  width: 50%;
}
.desc-container img {
  width: 300px;
}
.right-desc {
  width: 100%;
}
.right-desc p {
  text-align: justify;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 22px;
}
.description h2 {
  font-size: 20px;
}
.second-desc-container p {
  text-align: center;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  margin: 30px auto;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 900px;
}
.find-smth {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 5px 25px;
  margin: 5px auto 10px auto;
  color: #3084E2;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:110px;
  left: 40px;
}
.find-smth:hover {
  font-weight: 600;
  color: black;
}
.filters {
  display: inline-block;
  width: 900px;
}
.filter label {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
}
.filter-button {
  display: inline-block;
  height: 35px;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.boxes-container {
  width: 900px;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
.company-box2 {

}
}
@media only screen and (min-width: 451px) and (max-width: 900px) {
  .choosen-company-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 0px;
  margin: 0px;
}
  .desc-container {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    background-size: cover;
    background-position: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.description {
  padding: 5px;
  margin: 0px;
}
.map-desc-container {
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
}
.desc-container div {
  color: white;
}
.map-cont {
  width: 50%;
  margin: 5px;
}
.desc-container img {
  margin-left: 5px;
  width: 110px;
}
.right-desc {
  width: 100%;
  box-sizing: border-box;
}
.right-desc p {
  text-align: justify;
  margin: 0;
  padding: 3px;
  font-size: 14px;
  line-height: 1.2;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 23px;
}
.desc4 {
  font-size: 18px;
    margin: 5px 0;
}
.bigger {
  margin: 5px 0;
  font-size: 22px;
}
.desc3 {
  font-size: 17px;
  align-self: center;
  margin: 5px 0;
}
.italic {
  font-size: 20px;
    margin: 10px 0;
}
.description h2 {
  font-size: 20px;
}
.second-desc-container p {
  text-align: center;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 30px auto 15px auto;
  align-self: center;
  text-align: center;
}
.column-left-content {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.find-smth {
  font-weight: 600;
  width: 90px;
  font-size: 12px;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:35px;
  left:15px;
}
.find-smth:hover {
  font-weight: 600;
  color: black;
}
.filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 450px;
  float: left;
}
.filter {
  width: 100%;
  margin: 5px 0;
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.filter label {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
}
.filter select {
  width: 100%;
}
.filter-button-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filter-button {
  display: inline-block;
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  margin: 5px 20px;
  background-color: #3084E2;
}
.map-desc {
  width:100%;
  padding: 0;
  margin: 0;
}
.map {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
path {
  fill: #6d8b33;
}
path:hover {
  fill: #d2ca61;
  cursor: pointer;
}
svg {
  transform: scale(0.7);
}
svg text {
  display: none;
  z-index: 10;
}
svg path:hover text {
  display: block;
  z-index: 10;
}
.boxes-container {
  width: 100%;
  box-sizing: border-box;
}
.choosen-company-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: 3px solid #504DB7;
  margin: 0;
}
.column-container {
  position: relative;
  margin: 15px 10px 15px 10px;
}
.left-column,
right-column {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%
}
.left-column {
  justify-content: center;
  align-items: center;
}
.prod-name {
  font-size: 20px;
  margin-top: 0;
}
.logo {
  width: 70%;
}
.right-column {
  justify-content: center;
  align-items: flex-start;
}
.cross {
  width:20px;
  top: 5px;
  right: 5px;
  z-index: 1000;
}
.description-choosen-company {
  width: 100%;
  box-sizing:border-box;
  padding: 10%;
  color: black;
}
.description-choosen-company p {
  color: black;
}
.company-desc {
  margin: 0;
  padding: 0;
  color: black;
}
.icon-box {
  margin: 5px;
}
.icon-desc {
  height: auto;
}
.icon-desc h5 {
  float: left;
  font-size: 14px;
}
.icons img {
  width: 30px;
  margin-right: 5px;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
}
@media only screen and (max-width: 450px) {
.choosen-company-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 0px;
  margin: 0px;
}
  .desc-container {
    height: auto;
    background-size: cover;
    background-position: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width:100%;
}
#tooltip1 {
    opacity: 1;
    display: none;
    position: absolute;
    color: #3084E2;
    background-color: rgba(240, 248, 255, 0.9);
    padding: 10px 15px;
    border-radius: 15px;
    z-index: 1000;
    pointer-events: none;
}
.description {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 0px;
}
.map-desc-container {
  flex-direction: column;
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
}
.desc-container div {
  color: white;
}
.map-cont {
  width: 150px;
  margin: 5px auto;
}
.desc-container img {
  width: 150px;
}
.right-desc {
  width: 100%;
}
.right-desc p {
  text-align: justify;
  width: 100%;
  margin: 0;
  padding: 3px;
  font-size: 14px;
  line-height: 1.2;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 23px;
      margin: 5px 0;
}
.desc4 {
  font-size: 18px;
      margin: 5px 0;
}
.bigger {
  font-size: 22px;
      margin: 5px 0;
}
.desc3 {
  font-size: 17px;
  align-self: center;
     margin: 5px 0;
}
.italic {
  font-size: 20px;
  margin: 10px 0;
}
.description h2 {
  font-size: 20px;
}
.second-desc-container p {
  text-align: center;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px auto 10px auto;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 100%;
}
.find-smth {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding: 5px 25px;
  color: #36454f;
  margin: 5px auto 10px auto;
  color: black;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:40px;
  left: 0px;
}
.find-smth:hover {
  font-weight: 600;
  color: #3084E2;
}
.bigger {
  font-size: 22px;
}
.filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin: 10px 0;
  padding: 20px;
}
.filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 0;
}
.filter select {
  width: 100%;
}
.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.onefilter-container {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filter label {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 500;
  font-size: 15px;
}
.filter-button-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  jusity-content: center;
  align-items: center;
  padding: 10px 0;
}
.filter-button {
  display: inline-block;
  align-self: flex-end;
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  margin: 0px;
  background-color: #3084E2;
}

.map {
  width: 100%;
  height: auto;
  margin:-100px 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
path {
  fill: #6d8b33;
}
path:hover {
  fill: #d2ca61;
  cursor: pointer;
}
.map-desc {
  margin-top: 50px;
  font-size: 19px;
  font-weight: 600;
}
svg text {
  display: none;
  z-index: 10;
}
svg path:hover text {
  display: block;
  z-index: 10;
}
svg {
  transform: scale(0.5);
  margin: 0;
  paddin: 0;
}
.boxes-container {
  width: 100%;
  box-sizing: border-box;
}
.choosen-company-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border: 3px solid #504DB7;
  margin: 0;
  padding: 0;
}
.column-container {
  position: relative;
  margin: 15px 10px 15px 10px;
}
.left-column,
right-column {
  margin: 10px;
  display: flex;
  flex-direction: column;
  height: 100%
}
.left-column {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.contact-and-name {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.prod-name {
  font-size: 17px;
  margin-top: 0;
}
.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.logo {
  width: 70%;
}
.right-column {
  justify-content: center;
  align-items: flex-start;
}
.cross {
  width:20px;
  top: 5px;
  right: 5px;
  z-index: 1000;
}
.company-desc {
  margin: 0;
  padding: 10px;
}
.icon-box {
  margin: 5px;
}
.icon-desc h5 {
  float: left;
  text-align: left;
  font-size: 14px;
}
.icons img {
  width: 25px;
  margin-right: 5px;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
}

.region-labels {
  position: relative;
  width: 100%;
  height: 100%;
}

.region-name {
  position: absolute;
  font-size: 10px;
  color: #000000;
  font-weight: bold;
  transform: translate(-50%, -50%);
  font-family: 'Montserrat', sans-serif;
  pointer-events: none;
}

@media (max-width: 768px) {
  .region-name {
    font-size: 10px;
  }
}

.map-paths path {
  cursor: pointer;
}

.map-cont1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.map3 {
  position: relative;
  width: 612.75696px;
  height: 577.23169px;
  margin: 30px auto;
}

.map3 svg {
  width: 100%;
  height: 100%;
  display: block;
}

.map-paths {
  width: 100%;
  height: 100%;
}

.map-paths path {
  cursor: pointer;
  fill: #6d8b33;
  transition: fill 0.3s ease;
}

.map-paths path:hover {
  fill: #d2ca61;
}

#tooltip1 {
  opacity: 1;
  display: none;
  position: absolute;
  color: #3084E2;
  background-color: rgba(240, 248, 255, 0.9);
  padding: 10px 15px;
  border-radius: 15px;
  z-index: 1000;
  pointer-events: none;
}

@media (max-width: 768px) {
  .map-cont1 {
    height: 400px;
    margin-top: -50px; /* zmniejszony margines z -150px */
    margin-bottom: 50px; /* zmniejszony margines ze 120px */
    transform: scale(1) translateX(0); /* usunięte przesunięcie i zmniejszona skala */
    transform-origin: center center;
    width: 100%; /* zmienione z 95% na 100% */
    margin-left: auto;
    margin-right: auto;
    overflow: visible; /* dodane */
  }

  .map3 {
    display: flex;
    justify-content: center;
    width: 100%; /* dodane */
    height: 100%; /* dodane */
  }

  svg {
    width: 100%; /* dodane */
    height: auto; /* dodane */
    transform: scale(0.9); /* delikatnie zmniejszona skala */
    transform-origin: center center; /* dodane */
  }
}

@media only screen and (max-width: 540px) {
  .map-cont1 {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px; /* dodane */
  }

  svg {
    transform: scale(0.8); /* zwiększona skala z 0.5 */
  }
}
</style>
