<template>
<div class="form-container">
  <div>
    <h3>Jeżeli świadczysz usługi o ponadprzeciętnej jakości zostań Partnerem Portalu Mendel Helps Life</h3>
    <p> Zapisz się do naszej bazy*</p>
  </div>
  <form @submit="addCompany" enctype="multipart/form-data">
    <div>
      <div class="input-field">
        <label>Nazwa lub imię i nazwisko *</label>
        <input type="text" v-model="company.prodName" ref="prodName" @blur="validateName(this.company.prodName)"/>
        <p class="required-input">* To pole jest obowiązkowe</p>
      </div>
      <div class="error">
        <p>{{ error.nameRequired }}</p>
      </div>
      <div class="input-field">
        <label>Ulica, nr domu *</label>
        <input type="text" v-model="prodStreet"/>
      </div>
      <div class="input-field">
        <label>Kod pocztowy i miasto *</label>
        <div class="zipcode-container">
          <input
            class="zipcode1"
            type="text"
            maxlength="2"
            v-model="prodZipCode1"
            @blur="validateZipCode1(this.prodZipCode1)"
            placeholder="12"
          /> -
            <input
            class="zipcode2"
            type="text"
            maxlength="3"
            v-model="prodZipCode2"
            @blur="validateZipCode2(this.prodZipCode2)"
            placeholder="345"
          />
          <input type="text" v-model="prodCity" class="city" @blur="validateProdCity(this.prodCity)"/>
        </div>
        <p class="required-input">* To pole jest obowiązkowe</p>
      </div>
      <div class="error">
        <p>{{ error.zipcode }}</p>
      </div>
      <div class="input-field">
        <label for="woj">Województwo *</label>
        <select id="woj" v-model="company.woj" @change="validateWoj(this.company.woj)">
            <option value="dolnośląskie">dolnośląskie</option>
            <option value="kujawsko-pomorskie">kujawsko-pomorskie</option>
            <option value="lubelskie">lubelskie</option>
            <option value="łódzkie">łódzkie</option>
            <option value="małopolskie">małopolskie</option>
            <option value="mazowieckie">mazowieckie</option>
            <option value="opolskie">opolskie</option>
            <option value="podkarpackie">podkarpackie</option>
            <option value="podlaskie">podlaskie</option>
            <option value="pomorskie">pomorskie</option>
            <option value="śląskie">śląskie</option>
            <option value="świętokrzyskie">świętokrzyskie</option>
            <option value="łódzkie">łódzkie</option>
            <option value="warmińsko-mazurskie">warmińsko-mazurskie</option>
            <option value="wielkopolskie">wielkopolskie</option>
            <option value="zachodniopomorskie">zachodniopomorskie</option>
        </select>
        <p class="required-input">* To pole jest obowiązkowe</p>
      </div>
        <div class="error">
        <p>{{ error.wojRequired }}</p>
      </div>
                  <!--DANE DO FAKTURY -->
      <div class="invoice-data">
      <p class="invoice-header">Dane do faktury*</p>
      <p class="required-input">* Dale adresowe uzupełnij jeżeli są inne niż miejsce prowadzenia działalności.</p>
        <div class="input-field">
          <label>Nazwa firmy *</label>
          <input type="text" v-model="company.invoiceProdName" ref="prodName"/>
        </div>
        <div class="input-field">
          <label>Ulica, nr domu *</label>
          <input type="text" v-model="invoiceProdStreet"/>
        </div>
        <div class="input-field">
          <label>Kod pocztowy i miasto *</label>
          <div class="zipcode-container">
            <input
              class="zipcode1"
              type="text"
              maxlength="2"
              v-model="invoiceProdZipCode1"
              @blur="validateInvoiceZipCode1(this.invoiceProdZipCode1)"
              placeholder="12"
            /> -
              <input
              class="zipcode2"
              type="text"
              maxlength="3"
              v-model="invoiceProdZipCode2"
              @blur="validateInvoiceZipCode2(this.invoiceProdZipCode2)"
              placeholder="345"
            />
            <input type="text" v-model="invoiceProdCity" class="city"/>
          </div>
        </div>
        <div class="error">
          <p>{{ error.invoiceZipcode }}</p>
        </div>
        <div class="input-field">
          <label>Numer NIP</label>
          <input type="text" v-model="company.NIP" placeholder="PL1234567890"/>
          <p class="required-input"> * Dla podmiotów prowadzących działalność gospodarczą, płatników VAT</p>
        </div>
      </div>
      <!--KONIEC DANYCH DO FAKTURY -->
      <div class="input-field">
        <label>Adres mailowy</label>
        <input type="text" v-model="company.mail" @blur="validateEmail(this.company.mail)" placeholder="example@domain.com"/>
      </div>
        <div class="error">
        <p>{{ error.email }}</p>
      </div>
      <div class="input-field">
        <label>Telefon komórkowy *</label>
        <input type="text" v-model="company.tel1" @blur="validateMobilePhone(this.company.tel1)" placeholder="123 456 789"/>
        <p class="required-input">* To pole jest obowiązkowe</p>
      </div>
        <div class="error">
        <p>{{ error.mobile }}</p>
      </div>
        <div class="input-field">
        <label>Telefon komórkowy 2</label>
        <input type="text" v-model="company.tel3" @blur="validateMobilePhone1(this.company.tel3)" placeholder="123 456 789"/>
      </div>
        <div class="error">
        <p>{{ error.mobile2 }}</p>
      </div>
    <div class="input-field">
        <label>Telefon stacjonarny</label>
        <input type="text" v-model="company.tel2" @blur="validatePhone(this.company.tel2)" placeholder="12 345 67 89"/>
      </div>
        <div class="error">
        <p>{{ error.phone }}</p>
      </div>
      <div class="input-field">
        <label>Adres strony internetowej</label>
        <input type="text" v-model="company.webSite" @blur="validateWebSite(this.company.webSite)" placeholder="https://example.com"/>
      </div>
      <div class="error">
        <p>{{ error.site }}</p>
      </div>
      <div class="input-field">
        <label>Adres strony Facebook</label>
        <input type="text" v-model="company.facebook" @blur="validateFacebook(this.company.facebook)" placeholder="https://www.facebook.com/exapmle"/>
      </div>
      <div class="error">
        <p>{{ error.facebook }}</p>
      </div>
      <div class="input-field">
        <label>Adres strony Instagram</label>
        <input type="text" v-model="company.instagram" @blur="validateInstagram(this.company.instagram)" placeholder="https://www.instagram.com/example"/>
      </div>
      <div class="error">
        <p>{{ error.instagram }}</p>
      </div>
        <div class="input-field">
        <label>Opisz swoją działalność:</label>
        <span class="message-counter">{{ marksRemain }} / 600</span>
        <textarea v-model="company.description" rows="15" cols="55" maxlength="600"></textarea>
      </div>
      </div>
      <div><h4>Jakie usługi oferujesz? Wybierz z poniższych</h4></div>
      <div class="inputFile-container">
        <div><h3>Rodzaje świadczonych usług</h3></div>
            <div class="check-box">
                <label class="container-checkbox">sanatoria
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="sanatoria"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">SPA
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="SPA"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">wellness
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="wellness"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">SPA &amp; wellness
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="SPA &amp; wellness"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">rehabilitacja
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="rehabilitacja"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">fizjoterapia
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="fizjoterapia"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">fizykoterapia
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="fizykoterapia"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">fitness
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="fitness"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">inne
                    <input type="checkbox" id="rehabilitation" name="rehabilitation" value="inne"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
        </div>
        <div class="btn-container"><button @click="doNothing" disabled>Zatwierdź wybór</button></div>
        <p class="required-input">* Umieszczenie danych Podmiotu na Portalu pociąga za sobą konieczność poniesienia następujących
        okresowych, cyklicznych opłat - 15,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie
        za okres 12 m-cy lub 29,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 6
        m-cy dla wybranego segmentu</p>
        <div class="inputFile-container">
        <div><h3>Wybierz dodatkowe wyróżnienia Twojej firmy</h3></div>
            <div class="check-box">
                <label class="container-checkbox">Pogrubienie czcionki i dodanie adresu strony internetowej*
                    <input type="checkbox" id="fontSite" name="distinctions" value="pogrubienie czcionki i strona internetowa"/>
                    <span class="checkmark"></span>
                </label><br>
                <div class="cost-description"><p>*dodatkowo 1,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 12 m- cy<br/>
                lub 3,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 6 m-cy</p></div>
            </div>
            <div class="check-box">
                <label class="container-checkbox">Dodanie logo (sygnet), zdjęcia lub grafiki*
                    <input type="checkbox" id="logo" name="distinctions" value="logo"/>
                    <span class="checkmark"></span>
                </label><br>
                <div class="cost-description"><p>*dodatkowo 3,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 12 m- cy<br/>
                lub 5,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 6 m-cy</p></div>
                <div class="inputFile-container1">
                    <p class="desc-logo input-field">Wybierz logo, zdjęcie lub grafikę</p><input type="file" v-on:change="handleFileUpload($event)" name="logo"/>
               </div>
            </div>
            <div class="check-box">
                <label class="container-checkbox">Twoja firma w pierwszych 30 miejscach*
                    <input type="checkbox" id="firstPlace" name="distinctions" value="pierwsze 30 miejsc"/>
                    <span class="checkmark"></span>
                </label><br>
                 <div class="cost-description"><p>*dodatkowo 7,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 12 m- cy<br/>
                lub 9,87 złotych brutto miesięcznie przy założeniu opłaty z góry łącznie za okres 6 m-cy</p></div>
            </div>
        </div>
        <div class="error">
        <p>{{ error.send }}</p>
      </div>
      <div class="btn-container"><input type="submit" value="Wyślij formularz" @click="addCompany"/>
        <p class="cost-description" style="margin: 0; padding: 0;">* Płatność na podstawie faktury pro forma, którą otrzymasz po wysłaniu formularza</p>
        <p class="cost-description" style="margin: 0; padding: 0;">* Wysyłając formularz oświadczasz, że zapoznałeś się z Regulaminem i Polityką Prywatności Mendel Helps Life</p>
      </div>
      <div>
        <p class="alert-ok">{{responseAlertOK}}</p>
        <p class="alert-error">{{responseAlertError}}</p>
    </div>
  </form>
</div>
</template>
<script>
//import axios from "axios";
import CompaniesDataService from "../GetCompanies.js";
import axios from "axios";
export default {
  data() {
    return {
      baseCompanies: [],
      company: {
        prodName: "",
        prodAdress: "",
        woj: "",
        NIP: "",
        mail: "",
        tel1: "",
        tel2: "",
        tel3: "",
        webSite: "",
        facebook: "",
        instagram: "",
        description: ""
      },
      prodStreet: "",
      prodZipCode1: "",
      prodZipCode2:"",
      prodCity: "",
      file: "",
      error: {
        zipcode: "",
        email: "",
        mobile: "",
        phone: "",
        mobile2: "",
        facebook: "",
        instagram: "",
        send: "",
        nameRequired: "",
        prodZipCodeRequired: "",
        wojRequired: "",
      },
      sendForm: true,
      responseAlertOK: "",
      responseAlertError: ""
    };
  },
  computed: {
    marksRemain() {
      var descLength = Number(this.company.description.length);
      var marks = 600 - descLength;
      return marks;
    }
  },
  methods: {
    addCompany() {
      console.log(this.sendForm);
      event.preventDefault();
      this.validateBeforeSend();
      console.log(this.sendForm);
      if(this.sendForm) {
      event.preventDefault();
      this.error.send = "";
      var zipcode = this.prodZipCode1 + "-" + this.prodZipCode2;
      this.company.prodAdress =
        this.prodStreet + ", " + zipcode + " " + this.prodCity;
      console.log(this.company.prodAdress);
      var invoiceZipCode = this.invoiceProdZipCode1 + "-" + this.invoiceProdZipCode2;
      this.company.invoiceProdAdress =
        this.invoiceProdStreet + ", " + invoiceZipCode + " " + this.invoiceProdCity;
      if (this.sendForm) {
        var checkedRehabilitation = [];
        event.preventDefault();
        let checkboxesRehabilitation = document.getElementsByName("rehabilitation");
        //let checkedSubcategories = [];
        for (var i = 0; i < checkboxesRehabilitation.length; i++) {
          if (checkboxesRehabilitation[i].checked) {
            checkedRehabilitation.push(checkboxesRehabilitation[i].value);
            checkedRehabilitation.toString();
          }
        }
        console.log(checkedRehabilitation);

        var distinctions = [];
        event.preventDefault();
        let checkboxesDistinctions= document.getElementsByName("distinctions");
        //let checkedSubcategories = [];
        for (var j = 0; j < checkboxesDistinctions.length; j++) {
          if (checkboxesDistinctions[j].checked) {
            distinctions.push(checkboxesDistinctions[j].value);
            distinctions.toString();
          }
        }
        console.log(distinctions);
       // var products = JSON.stringify(this.clientsCat);

        const formData = new FormData();
        formData.append("woj", this.company.woj);
        formData.append("prodName", this.company.prodName);
        formData.append("prodAdress", this.company.prodAdress);
        formData.append("invoiceProdName", this.company.invoiceProdName);
        formData.append("invoiceProdAdress", this.company.invoiceProdAdress);
        formData.append("NIP", this.company.NIP);
        formData.append("mail", this.company.mail);
        formData.append("tel1", this.company.tel1);
        formData.append("tel2", this.company.tel2);
        formData.append("tel3", this.company.tel3);
        formData.append("webSite", this.company.webSite);
        formData.append("facebook", this.company.facebook);
        formData.append("instagram", this.company.instagram);
        formData.append("rehabilitation", checkedRehabilitation);
        // formData.append("subcat", checkedSubcategories);
        formData.append("opis", this.company.description);
        formData.append("wyroznienia", distinctions);
        if(this.file !== ""){
          formData.append("file", this.file, this.file.name);
        }

         axios.post("../PHPMailer/phpmailrehabilitation.php", formData, {}).then((res) => {
          console.log(res);
          if(res.status !== 200) {
          this.responseAlertError = "Coś poszło nie tak. Sprawdź rozmiar zdjęcia i poprawność danych.";
            setTimeout(()=> {
              this.responseAlertError = ""
            }, 3000);
          } else if(res.status == 200) {
            this.responseAlertOK = "Wiadomość została wysłana.";
            setTimeout(()=> {
              this.responseAlertOK = "";
              location.reload();
              //router.push({ path: '/productsForm' })
            }, 5000
            )
          }
        });
      }
      } else {
        this.error.send = "Upewnij się, że wszystkie pola zostały poprawnie uzupełnione.";
      }
    },
    removeChoosenCategories() {
      this.clientsCat = [];
    },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      CompaniesDataService.uploadLogo(formData);
    },
    handleFileUpload(event) {
      event.preventDefault();
      this.file = event.target.files[0];
    },
    validateBeforeSend() {
        this.sendForm = true;
      if(this.error.zipcode != '' || this.error.mobile != '' || this.error.phone != ''
      || this.error.mobile2 != '' || this.error.facebook != '' || this.error.instagram != ''
      ) {
        console.log(this.error.zipcode);
        console.log(this.error.mobile);
        console.log(this.error.phone);
        console.log(this.error.mobile2);
        console.log(this.error.facebook);
        console.log(this.error.instagram);
        this.sendForm = false;
        this.error.send = "Upewnij się, że wszystkie pola zostały prawidłowo uzupełnione";
      } else {
        this.sendForm = true;
      }
      if(this.company.prodName == "") {
        this.sendForm = false;
        this.error.nameRequired = "To pole jest obowiązkowe";
      }
      if(this.prodZipCode1 == "") {
        this.sendForm = false;
        this.error.zipcode = "To pole jest obowiązkowe";
      }
      if(this.prodZipCode2 == "") {
        this.sendForm = false;
        this.error.zipcode = "To pole jest obowiązkowe";
      }
        if(this.prodCity == "") {
        this.sendForm = false;
        this.error.zipcode = "To pole jest obowiązkowe";
      }
        if(this.company.woj == "") {
        this.sendForm = false;
        this.error.wojRequired = "To pole jest obowiązkowe";
      }
        if(this.company.tel1 == "") {
        this.sendForm = false;
        this.error.mobile = "To pole jest obowiązkowe";
      }

    },
    validateName(name) {
      if(name !== '') {
        this.error.nameRequired = "";
      } else {
        this.error.nameRequired = "To pole jest obowiązkowe";
      }
    },
    validateZipCode1(code) {
      const regex = /^[0-9]{2}$/;
      if(code !== ''){
        if (!regex.test(code)) {
        this.error.zipcode = "Wprowadź poprawny kod pocztowy.";
        this.sendForm = false;
        } else {
        this.error.zipcode = "";
        this.sendForm  = true;
        }
      } else {
        this.error.zipcode = "To pole jest obowiązkowe";
        this.sendForm  = false;
        return;
      }
    },
        validateZipCode2(code) {
      const regex = /^[0-9]{3}$/;
      if(code !== ''){
        if (!regex.test(code)) {
        this.error.zipcode = "Wprowadź poprawny kod pocztowy.";
        this.sendForm = false;
        } else {
        this.error.zipcode = "";
        this.sendForm  = true;
        }
      } else {
        this.error.zipcode = "To pole jest obowiązkowe";
        this.sendForm  = false;
        return;
      }
    },
        validateInvoiceZipCode1(code) {
      const regex = /^[0-9]{2}$/;
      if(code !== ''){
        if (!regex.test(code)) {
        this.error.invoiceZipcode = "Wprowadź poprawny kod pocztowy.";
        this.sendForm = false;
        } else {
        this.error.invoiceZipcode = "";
        this.sendForm  = true;
        }
      } else {
        this.error.invoiceZipcode = "";
        this.sendForm  = true;
        return;
      }
    },
    validateInvoiceZipCode2(code) {
      const regex = /^[0-9]{3}$/;
      if(code !== ''){
        if (!regex.test(code)) {
        this.error.invoiceZipcode = "Wprowadź poprawny kod pocztowy.";
        this.sendForm = false;
        } else {
        this.error.invoiceZipcode = "";
        this.sendForm  = true;
        }
      } else {
        this.error.invoiceZipcode = "";
        this.sendForm  = true;
        return;
      }
    },
    validateProdCity(city) {
      if(city !== "") {
        this.error.zipcode = "";
        this.sendForm  = true;
      } else {
        this.error.zipcode = "To pole jest obowiązkowe";
        this.sendForm  = false;
        return;
      }
    },
    validateWoj(woj) {
      if(woj !== "") {
        this.error.wojRequired = "";
        this.sendForm  = true;
      } else {
        this.error.wojRequired = "To pole jest obowiązkowe";
        this.sendForm  = false;
        return;
      }
    },
    validateEmail(email) {
        if(email !== ''){
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
         if (!regex.test(email)) {
                this.error.email = "Wpisz poprawny adres email.";
                this.sendForm = false;
            } else {
                this.error.email = "";
                this.sendForm  = true;
            }
        } else {
          return;
        }
        },
    validateMobilePhone(phone) {
        if(phone !== ''){
         const regex = /^\d{3} \d{3} \d{3}$/;
         if (!regex.test(phone)) {
                this.error.mobile = "Wpisz numer telefonu komórkowego w formacie 'XXX XXX XXX'.";
                this.sendForm = false;
            } else {
                this.error.mobile = "";
                this.sendForm  = true;
            }
        } else {
          this.error.mobile = "To pole jest obowiązkowe";
          this.sendForm  = false;
          return;
        }
    },
        validateMobilePhone1(phone) {
        if(phone !== ''){
         const regex = /^\d{3} \d{3} \d{3}$/;
         if (!regex.test(phone)) {
                this.error.mobile2 = "Wpisz numer telefonu komórkowego w formacie 'XXX XXX XXX'.";
                this.sendForm = false;
            } else {
                this.error.mobile2 = "";
                this.sendForm  = true;
            }
        } else {
          this.error.mobile2 = "";
          return;
        }
    },
    validatePhone(phone1) {
        if(phone1 !== ''){
            const regex = /^\+?([0-9]{2})[-. ]?([0-9]{3})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/;
         if (!regex.test(phone1)) {
                this.error.phone = "Wpisz numer telefonu w formacie 'XX XXX XX XX'.";
                this.sendForm = false;
            } else {
                this.error.phone = "";
                this.sendForm  = true;
            }
        } else {
          this.error.phone = "";
          return;
        }
    },
        validateWebSite(site) {
        if(site !== ''){
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(site)) {
                this.error.site = "Wpisz poprawny adres strony.";
                this.sendForm = false;
            } else {
                this.error.site = "";
                this.sendForm  = true;
            }
        } else {
          this.error.site = "";
          return;
        }
    },
      validateFacebook(facebookLink) {
        if(facebookLink !== ''){
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(facebookLink)) {
                this.error.facebook = "Wpisz poprawny adres strony facebook.";
                this.sendForm = false;
            } else {
                this.error.facebook = "";
                this.sendForm  = true;
            }
        } else {
          this.error.facebook = "";
          return;
        }
    },
          validateInstagram(instaLink) {
         if(instaLink !== ''){
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(instaLink)) {
                this.error.instagram = "Wpisz poprawny adres strony instagram.";
                this.sendForm = false;
            } else {
                this.error.instagram = "";
                this.sendForm  = true;
            }
         } else {
                this.error.instagram = "";
                return;
         }
    },
    doNothing() {
      console.log("confirmed");
    }
  },
  watch: {
    chosenCategory() {
      let category = this.chosenCategory;
      let typesArray = this.allCategories.find(
        (option) => option.name == category
      );
      this.typesFromCategory = typesArray;
    },
  },

};
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cost-description {
  font-size: 13px;
  width: 100%;
  text-align: left;
}
h3 {
    font-size: 25px;
    font-weight: 500;
    margin:0;
}
h4 {
    font-size: 20px;
    font-weight: 500;

}
form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
div {
  margin: 25px;
}
  label {
    font-size: 16px;
    color: #333;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .input-field {
    display: flex;
    flex-direction: column;
    margin-top: 15px 0 0 0;
  }
.invoice-data {
    padding: 20px;
    margin:25px 10px 25px 25px;
    border: 4px double #B71C1C;
  }
.invoice-header {
  font-size: 25px;
  margin: 0 0 5px 0;
}
input {
  width: 100%;
  font-size: 19px;
  padding: 5px;
}
input[type='checkbox'] {
  width: auto;
  margin: 0;
  padding:0;
}
option {
  width: 100%;
  font-size: 19px;
  padding: 5px;
}
select {
  width: 100%;
  padding: 5px;
  font-size: 19px;
}
.error {
  color: red;
  margin-top: 0;
}
.error p{
  margin:3px;
}
.response-alert-OK {
  color: green;
  font-size: 24px;
}
.response-alert-error {
  color: red;
  font-size: 24px;
}
.input-field {
  margin-bottom: 0;
}
input[type="submit"], button {
  font-size: 19px;
  background-color: #3084E2;
  padding: 10px;
  color: white;
  border: 1px solid #3084E2;
  border-radius: 15px;
  width: 100%;
  margin: 0 30px 15px 20px;
}
input[type="submit"]:hover, button:hover {
  background-color: #164b88;
  cursor: pointer;
}
.required-input {
  text-align: justify;
  font-size: 14px;
  margin: 0;
  padding:0;
}
.zipcode-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}
.zipcode1 {
  width:20px;
  display: inline;
}
.zipcode2 {
  width:32px;
  display: inline;
}
.city {
  margin-left: 15px;
}
::placeholder {
  color: #bdbebd;
}
.inputFile-container {
  margin: 0 20px;
}
.inputFile-container1 {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputFile-container1 p {
  font-size: 14px;
  width: 400px;
}
.inputFile-container1 input {
  display: inline-block;
}
.desc {
  margin: 30px 0 5px 0;
}
.desc-logo {
  float: left;
   margin: 0 20px;;
   font-weight: 600;
}
.desc p{
  margin: 3px;
}
.message-counter {
  align-self: flex-end;
  float: right;
}
/*styling checkboxes*/

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 18px;
  float: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  margin:0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-box {
    margin: 10px 0 20px 0;
  width: 100%;
}
textarea {
  resize: none;
}
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
  margin-bottom: 0;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 100px;
  text-wrap: wrap;
  word-wrap: break-word;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #164b88;
  color: white;
}
table p {
  margin: 0;
}
.removeCat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}
.remove-cat-button {
  widht: 100%;
  border-radius: none;
  border: none;
  margin-top:0;
  padding: 3px 5px;
  background-color: white;
  color: red;
  font-size: 15px;
}
.remove-cat-button:hover {
  background-color: #f2f2f2;
}
.btn-container {
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0;
}
.btn-container p {
  align-self: flex-start;
}
.btn-container input {
  margin-bottom: 5px;
}
.small {
  font-size: 15px;
  margin: 0;
}
.alert-ok {
  color: green;
  font-size: 23px;
  font-weight: 600;
}
.alert-error {
  color: red;
  font-size: 23px;
  font-weight: 600;
}
@media only screen and (max-width: 700px) {
.form-container {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 10px;
}
form {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5px;
}
.invoice-data {
    padding: 20px;
    margin:5px 10px 5px 5px;
  }
.invoice-data .input-field {
  margin: 0 15px 0 0;
}
.invoice-header {
  font-size: 22px;
  margin: 0 0 5px 0;
}
h3 {
    font-size: 20px;
    font-weight: 500;
    margin:0;
}
h4 {
  margin: 0;
  padding:0;
  font-size:18px;
}
.container-checkbox {
  font-size: 16px;
}
.inputFile-container1{
  flex-direction: column;
}
.inputFile-container1 .input-field{
  font-size: 12px;
  margin: 0;
}
}
</style>
