<template>
    <div class="contact-container1">
        <div class="header-container3">
                <div class="header2">
                    <p class="have-questions">JESTEŚMY DLA WAS</p>
                    <p class="big-bold">Skontaktuj się z nami </p>
                    <h3>Napisz nam co Cię spotkało. Masz pytania? Podziel się z nami opinią.</h3>
                </div>
                <div class="two-columns-container">
                    <div class="contact-container">
                      <a href="mailto:biurolife@mendelhelps.pl">
                        <div class="contact-box">
                            <img :src="images.mail"/>
                            <h4>Adres e-mail</h4>
                            <mail>biurolife@mendelhelps.pl</mail>
                        </div>
                      </a>
                      <a href="https://www.facebook.com/profile.php?id=61561230737851">
                        <div class="contact-box">
                            <img :src="images.facebook"/>
                            <h4>Facebook</h4>
                        </div>
                      </a>
                        <a href="https://www.instagram.com/mendelhelps">
                          <div class="contact-box">
                            <img :src="images.phone"/>
                            <h4>Instagram</h4>
                          </div>
                        </a>
                    </div>
                    <div class="contact-container1">
                        <form @submit="sendMail">
                                <div class="input-field">
                                    <label>Imię i nazwisko</label><br/>
                                    <input type="text" ref="prodName" v-model="dataToSend.name"/>
                                </div>
                                <div class="input-field">
                                    <label>adres e-mail</label>
                                    <input type="text" v-model="dataToSend.email"/>
                                </div>
                                <div class="input-field">
                                    <label>Treść wiadomości:</label><br>
                                    <span class="message-counter">{{ marksRemain }} / 600</span>
                                    <textarea rows="3" cols="55" maxlength="600" v-model="dataToSend.message"></textarea>
                                </div>
                                  <div>
                                    <p class="alert-ok">{{responseAlertOK}}</p>
                                    <p class="alert-error">{{responseAlertError}}</p>
                                  </div>
                                  <div class="inputFile-container1">
                                    <p class="desc-logo ">Wybierz zdjęcie.</p><p class="max-file-size">Dopuszczalny rozmiar pliku to 10MB.</p>
                                    <input type="file" ref="uploadfiles" name='file' v-on:change="handleFileUpload($event)"/>
                                    <div v-if="this.file !== ''" class="next-file"><input type="file" ref="uploadfiles" name='file1' v-on:change="handleFileUpload1($event)"/></div>
                                    <div v-if="this.file1 !== ''" class="next-file"><input type="file" ref="uploadfiles" name='file2' v-on:change="handleFileUpload2($event)"/></div>
                                    <div v-if="this.file2 !== ''" class="next-file"><input type="file" ref="uploadfiles" name='file3' v-on:change="handleFileUpload3($event)"/></div>
                                    <div v-if="this.file3 !== ''" class="next-file"><input type="file" ref="uploadfiles" name='file4' v-on:change="handleFileUpload4($event)"/></div>
                                  </div>
                                  <div class="confirm">
                                    <label class="confirm">
                                      <input type="checkbox" id="confirm" name="confirm" value="confirm"/>
                                    </label><br>
                                  </div>
                            <div class="btn-container">
                                <input type="submit" value="Wyślij" @click="sendMail" id="sendButton"/>
                                <p class="required-input">* Wysyłając wiadomość oświadczasz, że zapoznałeś się z Regulaminem i Polityką Prywatności Mendel Helps Life</p>
                            </div>
                        </form>
                      </div>
                </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import contact from '../photos/contact.jpeg';
import phone from "../photos/insta.png";
import mail from "../photos/mail.png";
import facebook from "../photos/facebook.png";
import insta from "../photos/insta.png";
export default {
    data() {
            return {
                dataToSend: {
                    name: '',
                    email: '',
                    message: ''
                },
                file: '',
                file1: '',
                file2: '',
                file3: '',
                file4: '',
                message: '',
                responseAlertOK: '',
                responseAlertError: '',
                images: {
                    contactImage: contact,
                    phone: phone,
                    mail: mail,
                    facebook: facebook,
                    insta: insta
                },
            }
        },
          computed: {
            marksRemain() {
            var descLength = Number(this.dataToSend.message.length);
            var marks = 600 - descLength;
            return marks;
    }
  },
  methods: {
       sendMail() {
          const sendButton = document.getElementById("sendButton");
          sendButton.disabled = true;
          sendButton.style.backgroundColor = "#C0C0C0";
          let bootsPrevent = document.getElementsByName("confirm");
          console.log(bootsPrevent);
            for (var c = 0; c < bootsPrevent.length; c++) {
              if (bootsPrevent[c].checked) {
                return;
              }
            }
        this.responseAlertOK = "Wysyłanie wiadomości...";
        event.preventDefault();
        const formData = new FormData();
        formData.append("name", this.dataToSend.name);
        formData.append("mail", this.dataToSend.email);
        formData.append("message", this.dataToSend.message);

        console.log(formData);
        if(this.file !== ""){
          formData.append("file", this.file, this.file.name);
        }
        if(this.file1 !== ""){
          formData.append("file1", this.file1, this.file1.name);
        }
        if(this.file2 !== ""){
          formData.append("file2", this.file2, this.file2.name);
        }
        if(this.file3 !== ""){
          formData.append("file3", this.file3, this.file3.name);
        }
        if(this.file4 !== ""){
          formData.append("file4", this.file4, this.file4.name);
        }
         axios.post("../PHPMailer/phpmailercontact.php", formData, {headers: {'Content-Type': 'multipart/form-data'
         }}).then((res) => {
          console.log(res);
          if(res.status !== 200) {
          this.responseAlertOK = "";
          this.responseAlertError = "Coś poszło nie tak. Sprawdź rozmiar zdjęcia i poprawność danych.";
            setTimeout(()=> {
              this.responseAlertError = ""
            }, 3000);
          } else if(res.status == 200) {
            this.responseAlertOK = "Wiadomość została wysłana.";
            setTimeout(()=> {
              this.responseAlertOK = "";
              location.reload();
              //router.push({ path: '/productsForm' })
            }, 5000
            )
          }
        });
      },
      handleFileUpload(event) {
        event.preventDefault();
        this.file = event.target.files[0];
        console.log(this.file, this.file.name);
    },
      handleFileUpload1(event) {
        event.preventDefault();
        this.file1 = event.target.files[0];
        console.log(this.file1, this.file1.name);
    },
      handleFileUpload2(event) {
        event.preventDefault();
        this.file2 = event.target.files[0];
        console.log(this.file2, this.file2.name);
    },
      handleFileUpload3(event) {
        event.preventDefault();
        this.file3 = event.target.files[0];
        console.log(this.file3, this.file3.name);
    },
    handleFileUpload4(event) {
        event.preventDefault();
        this.file4 = event.target.files[0];
        console.log(this.file4, this.file4.name);
    },
  }
 }
</script>

<style scoped>
.contact-container1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header-container3 {
    margin: 30px 0;
    width: 1450px;
    border-radius: 20px;
    padding-bottom: 50px;
    color: #3084E2;
}
.have-questions {
  color: #3084E2;
    font-size: 17px;
    margin: 50px 0 0 0;
}
.big-bold {
    font-size: 35px;
    margin: 5px 0 0 0;
    font-weight: 600;
    color: #3084E2;
}
h3 {
  color: #3084E2;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    color: #3084E2;
    margin-top: 70px;
    height: 100%;
}
.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    height: 820px;
    font-weight: 500;
}
.contact-container1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    height: 100%;
    font-weight: 500;
}
.contact-box {
    width: 250px;
    height: 140px;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    border-radius:20px;
    padding-top: 60px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.contact-box h4 {
    margin: 10px;
}
.contact-box img {
    height: 50px;
}
form {
    margin-top: 0 auto 0 auto;
    width: 400px;
    margin-left: 50px;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.form-container h3 {
  color: #3084E2;
}
.input-field {
  color: #3084E2;
    margin-top: 10px;
    margin: 30px;
}
.input-field label {
    font-size: 15px;
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 3px 0;
  color: #3084E2;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
}
.btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
}
.be-partner {
    border-radius: 20px;
}
a button {
    color: white;
    border-radius: ;
}
a button:hover {
    color: black;
}
button {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
}

button:hover {
    background-color: #85BEFF;
    cursor: pointer;
}
input[type=submit] {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
    width: 100%;
    border: none;
    color: white;
    margin: 25px 0;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px;
}
input[type=submit]:hover {
    color: black;
    background-color: #3084E2;
}
textarea {
    width: 100%;
    height: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

input[type=submit]:hover {
  background-color: #6baaf2;
}
.next-file {
  margin: 5px 0;
}
.max-file-size {
  margin: 3px 0;
  padding: 0;
  color: red;
  font-size: 13px;
}
.desc-logo {
  margin-bottom: 5px;
}
/*.form-container {
    width: 400px;
  margin-left: 50px;
  height: 100%;
}*/
.message-counter {
  align-self: flex-end;
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
.alert-ok {
  color: green;
  font-size: 24px;
}
.alert-error {
  color: red;
  font-size: 24px;
}
.confirm {
  padding: 0;
  margin: 0;
  opacity: 1;
  height: 0;
}
.confirm input {
  padding: 0;
  margin: 0;
  height: 0;
  opacity: 0;
}
.required-input {
  text-align: justify;
  font-size: 12px;
  margin: 0 0 30px 0;
  padding:0;
  color: black;
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
.header-container3 {
    box-sizing: border-box;
    margin: 30px 0;
    width: 590px;
    padding-bottom: 50px;
    color: #3084E2;
}
.have-questions {
  color: #3084E2;
    font-size: 17px;
    margin: 30px 0 0 0;
}
.big-bold {
    font-size: 35px;
    margin: 5px 0 0 0;
    font-weight: 600;
    color: #3084E2;
}
h3 {
  color: #3084E2;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
  color: #3084E2;
  margin-top: 50px;
  height: 650px;
}
.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    height: 100%;
    font-weight: 500;
}
.contact-box {
    width: 250px;
    height: 140px;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    border-radius:20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.contact-box h4 {
    margin: 10px;
}
.contact-box img {
    height: 50px;
}
form {
    margin-top: 0 auto 0 auto;
    width: 400px;
    margin-left: 10px;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.form-container h3 {
  color: #3084E2;
}
.input-field {
  color: #3084E2;
    margin-top: 10px;
    margin: 20px;
}
.input-field label {
    font-size: 15px;
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 3px 0;
  color: #3084E2;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
}
.btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
}
.be-partner {
    border-radius: 20px;
}
a button {
    color: white;
    border-radius: ;
}
a button:hover {
    color: black;
}
button {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

button:hover {
    background-color: #85BEFF;
    cursor: pointer;
}
input[type=submit] {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    width: 100%;
    border: none;
    color: white;
    margin: 5px 0;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px;
}
input[type=submit]:hover {
    color: black;
    background-color: #3084E2;
}
textarea {
    width: 100%;
    height: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

input[type=submit]:hover {
  background-color: #6baaf2;
}

/*.form-container {
    width: 400px;
  margin-left: 50px;
  height: 100%;
}*/
.message-counter {
  align-self: flex-end;
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
}
@media only screen and (max-width: 600px) {
.header-container3 {
    box-sizing: border-box;
    margin: 30px 0;
    width: 100%;
    padding-bottom: 20px;
    color: #3084E2;
    margin:0;
}
.have-questions {
  color: #3084E2;
    font-size: 17px;
    margin: 30px 0 0 0;
}
.big-bold {
    font-size: 28px;
    margin: 5px 0 0 0;
    font-weight: 600;
    color: #3084E2;
}
h3 {
  color: #3084E2;
}
.two-columns-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
  color: #3084E2;
  margin-top: 50px;
  height: auto;
}
.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    font-weight: 500;
}
.contact-container1 {
    margin-right: 0px;
}
.contact-box {
    width: 220px;
    height: 140px;
    background-color: #fff;
    padding: 10px;
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    border-radius:20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.contact-box h4 {
    margin: 10px;
}
.contact-box img {
    height: 50px;
}
form {
    margin: 30px auto 0 auto;
    width: 100%;
    margin-left: auto;
    height: 100%;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.45) 0px 8px 32px;
}
.form-container h3 {
  color: #3084E2;
}
.input-field {
  color: #3084E2;
    margin-top: 10px;
    margin: 20px;
}
.input-field label {
    font-size: 15px;
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 3px 0;
  color: #3084E2;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
}
p .desc-logo {
  border: 1px solid red;
}
.btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
}
.be-partner {
    border-radius: 20px;
}
a button {
    color: white;
    border-radius: ;
}
a button:hover {
    color: black;
}
button {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

button:hover {
    background-color: #85BEFF;
    cursor: pointer;
}
input[type=submit] {
    padding: 15px 25px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    width: 100%;
    border: none;
    color: white;
    margin: 5px 0;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px;
}
input[type=submit]:hover {
    color: black;
    background-color: #3084E2;
}
textarea {
    width: 100%;
    height: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #747474;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

input[type=submit]:hover {
  background-color: #6baaf2;
}

/*.form-container {
    width: 400px;
  margin-left: 50px;
  height: 100%;
}*/
.message-counter {
  align-self: flex-end;
  float: right;
  font-size: 13px;
  margin-bottom: 0;
}
}
</style>