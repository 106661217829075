<template>
<div>
    <div class="header-container">
            <p class="bold">Wytwarzasz produkty lub świadczysz usługi o ponadprzeciętnej jakości?</p>
            <h3 class="blue">Zostań naszym Partnerem!</h3>
            <div class="hight-carusel-conteiner3">
                <Flicking :options="{ circular: true }" :plugins="plugins3">
                    <div class="sentence-container3">
                        <img :src="backgrounds.img5" alt="triangle" class="triangle1"/>
                        <p class="sign-to-base">Wybierz sektor, w którym działasz:
                         PRODUKTY, WYPOCZYNEK, PODREPERUJ ZDROWIE</p>
                         <img :src="backgrounds.img5" alt="triangle" class="triangle2"/>
                    </div>
                    <div class="sentence-container" id="secondSentence1">
                        <p class="sign-to-base">Zapisz się do bazy Portalu
                        <img src="../../public/photos/Mendel_Helps_logo.png" class="logo-life"/></p>
                    </div>
                </Flicking>
            </div>
            <div class="box-container">
        <router-link to='/bePartnerHotelsForm'>
            <div class="box" :style="{ backgroundImage: 'url(' + backgrounds.img2 + ')' }">
                <h3>Wypoczynek</h3>
                <div class="desc-box">
                    <p>Agroturystyka, ośrodki wypoczynkowe, hotele, pensjonaty, inne</p>
                </div>
            </div>
        </router-link>
        <router-link to='/bePartnerProductsForm'>
            <div class="box" :style="{ backgroundImage: 'url(' + backgrounds.img1 + ')' }">
                <h3>Produkty</h3>
                <div class="desc-box">
                    <p>Artykuły spożywcze, prozdrowotne, kosmetyki, inne</p>
                </div>
            </div>
        </router-link>
        <router-link to='/BeRehabilitationForm'>
            <div class="box" :style="{ backgroundImage: 'url(' + backgrounds.img3 + ')' }">
                <h3>Podreperuj zdrowie</h3>
                <div class="desc-box">
                    <p>Sanatoria, ośrodki lecznicze i rehabilitacyjne, spa&amp;wellness, inne</p>
                </div>
            </div>
        </router-link>
    </div>
    <div class="under-icons">
        <h3>Zwiększ rzeszę swoich klientów, zwiększ swoją sprzedaż</h3>
    </div>
            <div class="blue-background-container">
                <div class="blue-background">
                    <h4>Gwarantujemy</h4>
                    <div class="two-columns-container">
                        <div class="column">
                            <p>Tobie:</p>
                            <ul>
                                <li>bezpieczeństwo, rzetelność i uczciwość usług, bo jesteśmy fachowcami</li>
                                <li>aktywną promocję na najbardziej popularnych forach społecznościowych</li>
                                <li>pomoc i doradztwo w możliwie szerokim zakresie</li>
                                <li>rozwój całego projektu bo to nasza ambicja, nasz cel i zadanie</li>
                            </ul>
                        </div>
                        <div class="column">
                            <p>Użytkownikom:</p>
                            <ul>
                                <li>możliwość dotarcia do najlepszych z najlepszych, czyli do naszych Partnerów</li>
                                <li>dobrodziejstwo obcowania z produktami i usługami naszych Partnerów</li>
                                <li>dostęp do ciekawie zaprezentowanej wiedzy o produktach i usługach</li>
                                <li>pomoc w prowadzeniu zdrowego trybu życia</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</template>
<script>
import Flicking from "@egjs/vue3-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import imgProducts from '../photos/produkty.jpeg';
import imgHotels from '../photos/wypoczynek3.jpeg';
import imgHealth from '../photos/sanatoria.jpeg';
import imgLogo from '../photos/Mendel_Helps_logo.png';
import triangle from '../photos/triangle.png';
    export default {
        data() {
            return {
                plugins3: [new AutoPlay({ duration: 3000, animationDuration: 6000, direction: "NEXT", stopOnHover: true})],
                backgrounds: {
                    img1: imgProducts,
                    img2: imgHotels,
                    img3: imgHealth,
                    img4: imgLogo,
                    img5: triangle
                }
            }
        },
        components: {
            Flicking: Flicking
        }
    }
</script>
<style scoped>
@import url("../../node_modules/@egjs/vue3-flicking/dist/flicking.css");
@import "../../node_modules/@egjs/flicking-plugins/dist/arrow.css";
.header-container {
    margin: 40px;
    box-sizing: border-box;
}
.header-container .bold {
    font-weight: 700;
}
.header-container p {
    margin:10px;
}
.header-container h3 {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 15px;
}
.header-container .blue {
    margin-top: 2
    0px;
    font-size: 32px;
    color: #3084E2;
}
.hight-carusel-conteiner3 {
    margin: 0 auto 30px auto;
    width: 1450px;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
}
.sentence-container3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 28px;
}
.triangle1, .triangle2 {
    width: 25px;
    margin: 0 15px;
}
.header-container h5 {
    font-size: 25px;
    margin: 20px auto;
}
.header-container .sign-to-base {
    margin: 0;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.blue-background-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blue-background {
    width:1450px;
    background-color: #3084E2;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    line-height: 25px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.blue-background h4 {
    color: white;
    font-size: 30px;
    font-weight: 600;
    margin-top: 30px;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin:20px 30px 30px 30px;
}
.sentence-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.column p {
    font-size: 21px;
}
.column ul li {
    float: left;
    text-align: left;
}
.box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 1450px;
    margin: 0 auto 30px auto;
}
.box-container a {
    width: 32%;

}
.box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 230px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    transition: 0.3s ease-out;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.box:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.box h3 {
    font-size: 20px;
    margin: 20px;
    padding: 8px 12px;
    background-color: white;
    border-radius: 10px;
    color: black;
}
a {
    text-decoration: none;
}
.desc-box {
    background-color: white;
    margin: 0 20px 0 20px;
    padding: 0 15px 0 15px;
    color: #5D4DB7;
    border-radius: 20px 20px 0 0;
}
.desc-box p {
    color: #131106;
    font-size: 16px;
    line-height: 1.3;
}
.under-icons h3 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1450px) {
.header-container {
    width: 1200px;
    margin: 30px auto;
    box-sizing: border-box;
}
.header-container .bold {
    font-weight: 700;
}
.header-container p {
    margin:10px;
}
.header-container h3 {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 15px;
}
.header-container .blue {
    margin-top: 20px;
    font-size: 32px;
    color: #3084E2;
}
.hight-carusel-conteiner3 {
    margin: 0 auto 30px auto;
    width: 1200px;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
}
.sentence-container3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 28px;
}
.triangle1, .triangle2 {
    width: 25px;
    margin: 0 15px;
}
.header-container h5 {
    font-size: 25px;
    margin: 20px auto;
}
.header-container .sign-to-base {
    margin: 0;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.blue-background-container {
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blue-background {
    width:1200px;
    background-color: #3084E2;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    line-height: 25px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.blue-background h4 {
    color: white;
    font-size: 30px;
    font-weight: 600;
    margin-top: 20px;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin:20px 30px 30px 30px;
}
.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.column p {
    font-size: 21px;
}
.column ul li {
    float: left;
    text-align: left;
}
.box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 1200px;
    margin: 0 auto 30px auto;
}
.box-container a {
    width: 32%;

}
.box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 230px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    transition: 0.3s ease-out;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.box:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.box h3 {
    font-size: 20px;
    margin: 20px;
    padding: 8px 12px;
    background-color: white;
    border-radius: 10px;
    color: black;
}
a {
    text-decoration: none;
}
.desc-box {
    background-color: white;
    margin: 0 20px 0 20px;
    padding: 0 15px 0 15px;
    color: #5D4DB7;
    border-radius: 20px 20px 0 0;
}
.desc-box p {
    color: #131106;
    font-size: 16px;
    line-height: 1.3;
}
.under-icons h3 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 30px;
}
}
@media only screen and (min-width: 901px) and (max-width: 1200px) {
.header-container {
    width: 900px;
    margin: 30px auto;
    box-sizing: border-box;
}
.header-container .bold {
    font-weight: 700;
}
.header-container p {
    margin:10px;
}
.header-container h3 {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 15px;
}
.header-container .blue {
    margin-top: 20px;
    font-size: 32px;
    color: #3084E2;
}
.hight-carusel-conteiner3 {
    margin: 0 auto 30px auto;
    width: 900px;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
}
.sentence-container3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 28px;
}
.triangle1, .triangle2 {
    width: 25px;
    margin: 0 15px;
}
.header-container h5 {
    font-size: 25px;
    margin: 20px auto;
}
.header-container .sign-to-base {
    margin: 0;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.blue-background-container {
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blue-background {
    width:900px;
    background-color: #3084E2;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    line-height: 25px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.blue-background h4 {
    color: white;
    font-size: 30px;
    font-weight: 600;
    margin-top: 20px;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin:20px 30px 30px 30px;
}
.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.column p {
    font-size: 21px;
}
.column ul li {
    float: left;
    text-align: left;
}
.box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 900px;
    margin: 0 auto 30px auto;
}
.box-container a {
    width: 32%;

}
.box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 230px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    transition: 0.3s ease-out;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.box:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.box h3 {
    font-size: 20px;
    margin: 20px;
    padding: 8px 12px;
    background-color: white;
    border-radius: 10px;
    color: black;
}
a {
    text-decoration: none;
}
.desc-box {
    background-color: white;
    margin: 0 20px 0 20px;
    padding: 0 10px 0 10px;
    color: #5D4DB7;
    border-radius: 20px 20px 0 0;
}
.desc-box p {
    color: #131106;
    font-size: 15px;
    line-height: 1.3;
}
.under-icons h3 {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 30px;
}
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
.header-container {
    width: 600px;
    margin: 20px auto;
    box-sizing: border-box;
}
.header-container .bold {
    font-weight: 700;
}
.header-container p {
    margin:10px;
}
.header-container h3 {
    font-size: 27px;
    margin-top: 0;
    margin-bottom: 15px;
}
.header-container .blue {
    margin-top: 20px;
    font-size: 32px;
    color: #3084E2;
}
.hight-carusel-conteiner3 {
    margin: 0 auto 30px auto;
    width: 600px;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
}
.sentence-container3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 18px;
}
.triangle1, .triangle2 {
    width: 25px;
    margin: 0 15px;
}
.header-container h5 {
    font-size: 25px;
    margin: 20px auto;
}
.header-container .sign-to-base {
    margin: 0;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.blue-background-container {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blue-background {
    width:600px;
    background-color: #3084E2;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    line-height: 25px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.blue-background h4 {
    color: white;
    font-size: 23px;
    font-weight: 600;
    margin-top: 20px;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin:10px 20px 20px 20px;
}
.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.column p {
    font-size: 20px;
}
.column ul li {
    float: left;
    text-align: left;
}
.box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 600px;
    margin: 0 auto 30px auto;
}
.box-container a {
    width: 32%;

}
.box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 300px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.box:hover {
    cursor: pointer;
}
.box h3 {
    font-size: 18px;
    margin: 20px;
    padding: 8px 12px;
    background-color: white;
    border-radius: 10px;
    color: black;
}
a {
    text-decoration: none;
}
.desc-box {
    background-color: white;
    margin: 0 20px 0 20px;
    padding: 0 10px 0 10px;
    color: #5D4DB7;
    border-radius: 20px 20px 0 0;
}
.desc-box p {
    color: #131106;
    font-size: 14px;
    line-height: 1.3;
}
.under-icons h3 {
    font-size: 23px;
    margin-top: 0;
    margin-bottom: 30px;
}
}
@media only screen and (max-width: 600px) {
.header-container {
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
}
.header-container .bold {
    font-weight: 700;
}
.header-container p {
    margin:10px;
}
.header-container h3 {
    font-size: 23px;
    margin-top: 0;
    margin-bottom: 15px;
}
.header-container .blue {
    margin-top: 20px;
    font-size: 26px;
    color: #3084E2;
}
.hight-carusel-conteiner3 {
    margin: 0 auto 30px auto;
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
}
.sentence-container3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 3%;
}
.triangle1, .triangle2 {
    width: 20px;
    margin: 0 15px;
}
.header-container h5 {
    font-size: 20px;
    margin: 20px auto;
}
.header-container .sign-to-base {
    margin: 0;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
}
.logo-life {
    width: 160px;
}
.blue-background-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.blue-background {
    width:100%;
    background-color: #3084E2;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    line-height: 25px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.blue-background h4 {
    color: white;
    font-size: 23px;
    font-weight: 600;
    margin-top: 20px;
}
.two-columns-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin:8px 10px 10px 10px;
}
.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.column p {
    font-size: 20px;
}
ul {
    padding-inline-start: 20px;
}
.column ul li {
    float: left;
    text-align: left;
    font-size: 14px;
    margin: 0;
    padding: 0;
}
.box-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    margin: 0 auto 20px auto;
}
.box-container a {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}
.box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 200px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.65) 0px 8px 32px;
}
.box:hover {
    cursor: pointer;
    transform: none;
}
.box h3 {
    font-size: 18px;
    margin: 20px;
    padding: 8px 12px;
    background-color: white;
    border-radius: 10px;
    color: black;
}
a {
    text-decoration: none;
}
.desc-box {
    background-color: white;
    margin: 0 20px 0 20px;
    padding: 0 10px 0 10px;
    color: #5D4DB7;
    border-radius: 20px 20px 0 0;
}
.desc-box p {
    color: #131106;
    font-size: 14px;
    line-height: 1.3;
}
.under-icons h3 {
    font-size: 23px;
    margin-top: 0;
    margin-bottom: 30px;
}
}
</style>