<template>
<div class="choosen-vege-container">
    <div class="background"></div>
        <div class="cross-container">
            <img :src="images.cross" alt="" class="cross" @click="closeChoosenVege"/>
        <div class="coodenVegeContainer">
            <div class="logo-desc">
                <img :src="'/photos/' + vege.img" alt="pic" />
                <div class="desc">
                    <h3>{{vege.name}}<br/><span v-if="vege.alcohol == 'TAK'" class="alcohol-alert">*Zapraszamy jeżeli masz ukończone 18 lat.</span></h3>
                    <p :class="{ showFull: showFullText }">{{description}}</p>
                    <button @click="showFullText = !showFullText"> Czytaj {{ showFullText ? "mniej" : "więcej" }}</button>
                </div>
            </div>
            <div class="otherInfo-container">
                <div class="do-you-know">
                    <div class="you-know-ask" @click="showDoYouKnow" :class="{ active: doYouKnowIsVisible }"><p>Czy wiesz, że?</p><span class="triangle">{{ doYouKnowIsVisible ? "▲" : "▼" }}</span></div>
                    <div v-if="doYouKnowIsVisible" class="doYouKnow-list">
                        <ul>
                            <li v-for="item in vege.goodToKnow" :key="item">{{item}}</li>
                        </ul>
                    </div>
                </div>
                <div class="do-you-know">
                    <div class="you-know-ask" @click="showBodyImpact" :class="{ active: bodyImpactIsVisible }"><p>Działanie na organizm</p><span class="triangle">{{ bodyImpactIsVisible ? "▲" : "▼" }}</span></div>
                    <div v-if="bodyImpactIsVisible" class="doYouKnow-list">
                        <ul>
                            <li v-for="item in vege.bodyImpact" :key="item">{{item}}</li>
                        </ul>
                    </div>
                </div>
                <div class="do-you-know">
                    <div class="you-know-ask" @click="showHowEatIsVisibe" :class="{ active: howEatIsVisible }"><p>Jak spożywać</p><span class="triangle">{{ howEatIsVisible ? "▲" : "▼" }}</span></div>
                    <div v-if="howEatIsVisible" class="doYouKnow-list">
                        <p>{{vege.howEat}}</p>
                    </div>
                </div>
                <div class="do-you-know">
                    <div class="you-know-ask" @click="showRecipesAreVisible" :class="{ active: recipesAreVisible }"><p>Przepisy</p><span class="triangle">{{ recipesAreVisible ? "▲" : "▼" }}</span></div>
                    <div v-if="recipesAreVisible" class="doYouKnow-list-recipes">
                        <div v-for="recipe in vege.recipes" :key="recipe.name" class="single-recipe">
                            <div><h3>{{recipe.name}}</h3></div>
                            <div>
                                <h4>Składniki: </h4>
                                <ul>
                                    <li v-for="item in recipe.ingrediens" :key="item">{{item.toUpperCase()}}</li>
                                </ul>
                            </div>
                            <div>
                                <h4>Przygotowanie: </h4>
                                <div v-if="recipe.preparation[recipe.preparation.length - 1].startsWith('Inny sposób przygotowania')">
                                    <ol>
                                        <li v-for="prepare in recipe.preparation.slice(0, recipe.preparation.length - 1)" :key="prepare">
                                            {{prepare}}
                                        </li>
                                    </ol>
                                    <b>{{ recipe.preparation[recipe.preparation.length - 1].split('-')[0] }}</b>
                                    <ol>
                                        <li v-for="(step, index) in recipe.preparation[recipe.preparation.length - 1].split('-').slice(1, recipe.preparation.length)" :key="index">
                                            {{ step }}
                                        </li>  
                                    </ol>
                                  
                                </div> 
                                <div v-else>
                                  <ol>
                                    <li v-for="prepare in recipe.preparation" :key="prepare">
                                        {{prepare}}
                                    </li>
                                </ol>
                                </div>
                                <br>
                                <p><b>SMACZNEGO!</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import cross from "../photos/cross.png";
export default {
    props: ['vege'],
    data() {
        return {
            showFullText:false,
            doYouKnowIsVisible: false,
            bodyImpactIsVisible: false,
            howEatIsVisible: false,
            recipesAreVisible: false,
            images: {
                cross: cross
            }
        }
    },
    computed: {
        description() {
                 if (this.showFullText) {
        return this.vege.description;
      }

      return `${this.vege.description.slice(0, 300).trim()}...`;
        }
    },
    methods: {
        closeChoosenVege() {
            this.$emit('closeWin');
        },
        showDoYouKnow() {
            this.doYouKnowIsVisible = !this.doYouKnowIsVisible;
            this.bodyImpactIsVisible = false;
            this.howEatIsVisible = false;
            this.recipesAreVisible = false;
        },
        showBodyImpact() {
            this.bodyImpactIsVisible = !this.bodyImpactIsVisible;
            this.doYouKnowIsVisible = false;
            this.howEatIsVisible = false;
            this.recipesAreVisible = false;
        },
        showHowEatIsVisibe() {
            this.howEatIsVisible = !this.howEatIsVisible;
            this.doYouKnowIsVisible = false;
            this.bodyImpactIsVisible = false;
            this.recipesAreVisible = false;
        },
        showRecipesAreVisible() {
            this.recipesAreVisible = !this.recipesAreVisible;
            this.doYouKnowIsVisible = false;
            this.bodyImpactIsVisible = false;
            this.howEatIsVisible = false;
        }
    }
}
</script>
<style>
.coodenVegeContainer {
   /* width: 1200px;
    height: 800px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -400px; /* Negative half of height.
    margin-left: -600px;*/
    height: 800px;
     display: flex;
     flex-direction: column;
    justify-content: space-around;
    align-items: center;
  flex-wrap: nowrap;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 20px;
  overflow-y: scroll;
  font-weight: 500;
}
.cross-container {
    width: 1200px;
    height: 800px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -400px; /* Negative half of height. */
    margin-left: -600px;
    flex-wrap: nowrap;
    z-index: 100;
    padding: 0;
    font-weight: 500;
}
.cross {
  width: 30px;
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
    z-index: 101;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.desc {
    background-color: #3084E2;
    padding: 20px;
    border-radius: 20px;
    margin: 30px 30px 15px 15px;
    color: white;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.55) 0px 8px 32px;
}
.desc button {
    color: black;
}
.desc h3 {
    font-size: 30px;
    color: black;
    font-weight: 600;
}
.logo-desc {
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.logo-desc p {
    padding: 30px;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
}
button {
    padding: 10px 20px;
    border-radius: 25px;
    font-family: "Montserrat", sans-serif;
    background-color: #3084E2;
    border: none;
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
    color: white;
}

button:hover {
    background-color: #85BEFF;
    cursor: pointer;
    color: black;
}
.otherInfo-container {
    width: 100%;
}
.do-you-know {
    overflow: auto;
    width: 100%;
}
.you-know-ask {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
    border-radius: 20px;
    padding: 4px 5px;
    margin: 7px 0 3px 0;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    border: 2px solid white;
}
.you-know-ask:hover {
    cursor: pointer;
    border: 2px solid #3084E2;
    background-color: #3084E2;
}
.you-know-ask p {
    padding: 0;
    line-height: 15px;
}
.triangle {
    position: absolute;
   top: 5px;
   right: 10px;
   font-size: 25px;
}
.triangle:hover {
    cursor: pointer;
}
.doYouKnow-list {
    transition: 2s ease-in-out .5s;
    padding: 10px;
    border-radius: 20px;
    margin: 20px auto;
    color: black;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
}
.doYouKnow-list-recipes {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
    margin-top: 0;
    height: 600px;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 20px;
    margin: 20px 0;
    color: black;
}
.doYouKnow-list ul li, .doYouKnow-list-recipes ul li{
    text-align: left;
    margin: 8px;
}
.doYouKnow-list ol li, .doYouKnow-list-recipes ol li{
    text-align: left;
    margin: 8px;
}
.doYouKnow-list-recipes h3 {
    font-size: 22px;
    color: black;
}
.single-recipe {
    margin: 80px auto;
}
.active {
    background-color: #3084E2;
    border-color: #3084E2;
}
.alcohol-alert {
    color: #990000;
    margin: 0 auto;
    padding: 0;
    font-size: 17px;
    font-weight: 500;
}
@media only screen and (max-height: 850px) {
 .coodenVegeContainer {
    /*height: auto;
    top: 50%;
    left: 50%;
    margin-top: -320px;  Negative half of height.
    margin-left: -600px;*/
  padding: 10px;
  overflow-y: scroll;
  border: none;
    height: 600px;
     display: flex;
     flex-direction: column;
    justify-content: space-around;
    align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  overflow-y: scroll;
  font-weight: 500;
}
.cross-container {
    width: 1200px;
    height: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -320px;  /*Negative half of height.*/
    margin-left: -600px;
    flex-wrap: nowrap;
    z-index: 100;
    padding: 0;
    font-weight: 500;
}
.desc {
    padding: 10px;
    margin: 10px;
}
.desc h3 {
    font-size: 25px;
    color: black;
    font-weight: 600;
}
.logo-desc {
    margin: 10px auto;
}
.logo-desc p {
    padding: 15px;
    font-size: 16px;
    line-height: 18px;
}
button {
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

.otherInfo-container {
    width: 100%;
}
.do-you-know {
    width: 100%;
}
.you-know-ask {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
    border-radius: 20px;
    padding: 4px 5px;
    margin: 5px 0 3px 0;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    border: 2px solid white;
}
.you-know-ask:hover {
    cursor: pointer;
    border: 2px solid #3084E2;
}
.you-know-ask p {
    padding: 0;
    line-height: 15px;
    margin: 10px;
}
.triangle {
    position: absolute;
   top: 5px;
   right: 10px;
   font-size: 25px;
}
.triangle:hover {
    cursor: pointer;
}
.doYouKnow-list {
    transition: 2s ease-in-out .5s;
    padding: 10px;
    border-radius: 20px;
    margin: 20px auto;
    color: black;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
}
.doYouKnow-list-recipes {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
    margin-top: 0;
    height: 600px;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 20px;
    margin: 20px 0;
    color: black;
}
.doYouKnow-list ul li, .doYouKnow-list-recipes ul li{
    text-align: left;
    margin: 8px;
}
.doYouKnow-list ol li, .doYouKnow-list-recipes ol li{
    text-align: left;
    margin: 8px;
}
.doYouKnow-list-recipes h3 {
    font-size: 22px;
    color: black;
}
.single-recipe {
    margin: 80px auto;
}
.cross {
  width: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
}
@media only screen and (min-width: 601px) and (max-width: 1200px) {
.choosen-vege-container {
    height: 100vw;
    position: relative;
}
.coodenVegeContainer {
 position: fixed;
  top: 0;
  left: 0;
  width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 25px;
  border: 5px solid #3084E2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 0;
  margin:0;
  overflow-y: scroll;
  font-weight: 500;
}
.cross-container {
    position: fixed;
    margin-top: 0;
    margin-left: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    flex-wrap: nowrap;
    z-index: 103;
    padding: 0;
    margin:0;
    font-weight: 500;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.desc {
    padding: 10px;
    margin: 10px;
}
.desc h3 {
    font-size: 25px;
    color: black;
    font-weight: 600;
}
.logo-desc {
    margin: 10px auto;
}
.logo-desc p {
    padding: 15px;
    font-size: 16px;
    line-height: 18px;
}
button {
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

.otherInfo-container {
    width: 100%;
}
.do-you-know {
    width: 100%;
}
.you-know-ask {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
    border-radius: 20px;
    padding: 4px 5px;
    margin: 5px 0 3px 0;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    border: 2px solid white;
}
.you-know-ask:hover {
    cursor: pointer;
    border: 2px solid #3084E2;
}
.you-know-ask p {
    padding: 0;
    line-height: 15px;
    margin: 10px;
}
.triangle {
    position: absolute;
   top: 5px;
   right: 10px;
   font-size: 25px;
}
.triangle:hover {
    cursor: pointer;
}
.doYouKnow-list {
    transition: 2s ease-in-out .5s;
    padding: 10px;
    border-radius: 20px;
    margin: 20px auto;
    color: black;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
}
.doYouKnow-list-recipes {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
    margin-top: 0;
    height: 600px;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 20px;
    margin: 20px 0;
    color: black;
}
.doYouKnow-list ul li, .doYouKnow-list-recipes ul li{
    text-align: left;
    margin: 8px;
}
.doYouKnow-list ol li, .doYouKnow-list-recipes ol li{
    text-align: left;
    margin: 8px;
}
.doYouKnow-list-recipes h3 {
    font-size: 22px;
    color: black;
}
.single-recipe {
    margin: 80px auto;
}
.cross {
  width: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
}
@media only screen and (max-width: 600px) {
.choosen-vege-container {
    height: 100vw;
    position: relative;
}
.coodenVegeContainer {
 position: fixed;
  top: 0;
  left: 0;
  width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 25px;
  border: 5px solid #3084E2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 0;
  margin:0;
  overflow-y: scroll;
  font-weight: 500;
}
.cross-container {
    position: fixed;
    margin-top: 0;
    margin-left: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    flex-wrap: nowrap;
    z-index: 103;
    padding: 0;
    margin:0;
    font-weight: 500;
}
.cross {
  width: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 102;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.desc {
    padding: 10px;
    margin: 10px;
    height: auto;
}
.desc h3 {
    font-size: 22px;
    color: black;
    font-weight: 600;
}
.logo-desc {
    margin: 10px auto;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    height: 1300px;
}
.logo-desc p {
    padding: 5px;
    font-size: 14px;
    line-height: 18px;
    overflow-y: scroll;
}
.showFull {
    height: 400px;
}
.logo-desc img {
    width: 40%;
}
button {
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

.otherInfo-container {
    width: 100%;
    height: 100%;
}
.do-you-know {
    height: auto;
    /*overflow-y: scroll;*/
}
.you-know-ask {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.15) 0px 8px 32px;
    border-radius: 20px;
    padding: 1px 5px;
    margin: 3px 0 3px 0;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    border: 2px solid white;
}
.you-know-ask:hover {
    cursor: pointer;
    border: 2px solid #3084E2;
}
.you-know-ask p {
    font-size: 16px;
    padding: 0;
    line-height: 15px;
    margin: 10px;
}
.triangle {
    position: absolute;
   top: 5px;
   right: 10px;
   font-size: 25px;
}
.triangle:hover {
    cursor: pointer;
}
.doYouKnow-list {
    height: auto;
    overflow-y: scroll;
    transition: 2s ease-in-out .5s;
    padding: 10px;
    border-radius: 20px;
    margin: 20px auto;
    color: black;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
}
.doYouKnow-list-recipes {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
    margin-top: 0;
    height: 800px;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 20px;
    margin: 20px 0;
    color: black;
}
.doYouKnow-list ul li, .doYouKnow-list-recipes ul li{
    text-align: left;
    margin: 8px;
}
.doYouKnow-list ol li, .doYouKnow-list-recipes ol li{
    text-align: left;
    margin: 8px;
}
.doYouKnow-list-recipes h3 {
    font-size: 22px;
    color: black;
}
.single-recipe {
    margin: 80px auto;
    height: 600px;
    width: 100%;
    overflow-y: scroll;
}
}
</style>