<template>
    <div class="desc-container" :style="{ backgroundImage: 'url(' + images.background1 + ')' }">
      <div class="description2">
      <div class="map-desc-container">
        <div class="right-desc1">
          <div class="inside-right-desc1">
            <h2>Wypoczynek, na jaki zasługujesz.</h2>
            <p class="italic">Czasy nam współczesne doczekały się wielu analiz i tyleż samo określeń.
            Praktycznie codziennie, czy to w środkach masowego przekazu, czy w mediach społecznościowych,
            w pracy, czy wreszcie w naszych codziennych rozmowach, padają słowa o szybko mijających dniach i biegu
            w jakim przyszło nam żyć.</p>
          </div>
        </div>
          <div @click="goToMap" class="go-to-map-container">
          <img :src="images.Poland" alt="mapa Polski"/>
          <button class="find-smth1">Znajdź wypoczynek</button>
        </div>
        </div>
      </div>
    </div>
  <div class="second-desc-container">
      <div class="column-left">
        <div class="column-left-content">
            <p class="italic">Mendel Helps Life pragnie Państwu pomóc w znalezieniu miejsc wypoczynku charakteryzujących się ponadprzeciętną jakością świadczonych usług.</p>
            <p>Nasi Partnerzy oferujący usługi z zakresu wypoczynku zapraszają
            Was zarówno do gospodarstw agroturystycznych z całym pakietem wspaniałości,
            jak i do swoich wspaniale położonych pensjonatów, domków, ośrodków wczasowych i
            hoteli różnego położenia i standardu.</p>
            <p  class="italic1">Nie trać czasu na poszukiwanie ofert,
            wejdź do naszej bazy i wybierz preferowaną przez siebie formę i miejsce wypoczynku.</p>
            <div class="bigger1"><p>Życzymy naładowania akumulatorów. </p></div>
        </div>
      </div>
        <div class="column-right">
          <img :src="images.wypoczynek" alt="wypoczynek"/>
      </div>
  </div>
  <div class="products-container">
    <div>
      <p class="map-desc">
        Wybierz województwo, a następnie wybierz kryteria wypoczynku.
      </p>
    </div>
    <div class="map-cont1">
      <PolandMap
        @clickWoj="clickWoj"
      />
    </div>
    <div class="products-container">
      <div>
        <p class="map-desc">
        </p>
      </div>
      <div class="map" id="map1">

      </div>

      <!-- Pokaż filtry tylko gdy województwo jest wybrane -->
      <div v-if="clickedWoj" class="filters">
        <div class="filter">
          <div>
            <div class="filter-container">
              <div class="onefilter-container">
                <h3>Udogodnienia</h3>
                <div v-for="type in facilities" :key="type" class="check-box">
                  <label class="container">{{ type }}
                    <input
                      type="checkbox"
                      id="facilities"
                      name="facilities"
                      :value="type"
                    />
                    <span class="checkmark"></span>
                  </label><br/>
                </div>
              </div>
              <div class="onefilter-container">
                <h3>Wyżywienie</h3>
                <div v-for="type in food" :key="type" class="check-box">
                  <label class="container">{{ type }}
                    <input
                      type="checkbox"
                      id="food"
                      name="food"
                      :value="type"
                    />
                    <span class="checkmark"></span>
                  </label><br/>
                </div>
              </div>
              <div class="onefilter-container">
                <h3>Atrakcje</h3>
                <div v-for="type in fun" :key="type" class="check-box">
                  <label class="container">{{ type }}
                    <input
                      type="checkbox"
                      id="fun"
                      name="fun"
                      :value="type"
                    />
                    <span class="checkmark"></span>
                  </label><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-button-container">
          <button @click="filter" class="filter-button">Filtruj</button>
        </div>
      </div>
    </div>
    <div v-if="isShownChoosenCompany" class="choosen-company">
      <div class="background"></div>
      <div class="choosen-company-box">
      <img :src="images.cross" class="cross" @click="closeWindow"/>
        <div class="column-container">
          <div class="right-column">
            <div class="icons">
              <div>
                <div class="icon-box">
                  <img :src="images.location" alt="location" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_producenta }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.phone" alt="phone number" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.tel1 }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.mail" alt="mail" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_mailowy }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.website" alt="website" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.adres_strony }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.facebook" alt="facebook" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.facebook }}</h5>
                  </div>
                </div>
                <div class="icon-box">
                  <img :src="images.insta" alt="instagram" />
                  <div class="icon-desc">
                    <h5>{{ choosenCompany.instagram }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-column">
            <div class="contact-and-name">
              <div class="logo-box">
                <img :src="choosenCompany.logo" class="logo" />
              </div>
              <div class="prod-name">{{ choosenCompany.nazwa_producenta }}</div>
            </div>
          </div>
        </div>
      <div class="description">
        <p class="company-desc">
          <span class="bolder-comp-desc">Tu będzie opis firmy i/lub działalności i/lub produktu zgodnie z Twoją decyzją.</span> Lorem Ipsum is simply dummy text of the printing
          and typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived
          not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in
          the 1960s with the release of Letraset sheets containing Lorem Ipsum
          passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum.
        </p>
      </div>
      </div>
    </div>
    <div class="boxes-container" v-if="companiesAreVisible">
    <!-- PRODUCTS CARUSEL -->
    <div class="products-carusel-above-container">
    <div class="products-carusel-conteiner">
        <!--<Flicking :options="{ circular: true, stopOnHover: true, preventClickOnDrag: false}" :plugins="pluginsProducts">-->
            <div class="company-box2">
              <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 1</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Radosna 56, 99-999 Jawor</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
                <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 2</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Miła 45, 99-999 Kłodzko</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 2','Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img :src="images.imgLogo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div class="be-partner">
            <p>Partnerzy</p>
          </div>
          <div>
            <img :src="images.imgLogoHelps" class="logo-small1" alt="Twoje logo"/>
          </div>
        </div>
        <div class="select">
          <router-link to="/bePartner"><button>Zostań Partnerem</button></router-link>
        </div>
      </div>
        <!--</Flicking>-->
      </div>
          <div class="products-carusel-conteiner1">
        <!--<Flicking :options="{ circular: true, stopOnHover: true, preventClickOnDrag: false}" :plugins="pluginsProducts">-->
            <div class="company-box2">
              <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 1</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Radosna 56, 99-999 Jawor</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
                <div class="box-first-part">
                <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
                <div>
                  <h5>Nazwa Firmy TEST 2</h5>
                </div>
                <div class="website-container">
                  <p class="website">www.twojafirma.pl</p>
                </div>
                <div>
                  <p>Adres:</p>
                  <p>Miła 45, 99-999 Kłodzko</p>
                </div>
              </div>
              <div class="select">
                <button @click="showTestCompany('Nazwa Firmy TEST 2','Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
              </div>
            </div>
            <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img :src="images.imgLogo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div class="be-partner">
            <p>Partnerzy</p>
          </div>
          <div>
            <img :src="images.imgLogoHelps" class="logo-small1" alt="Twoje logo"/>
          </div>
        </div>
        <div class="select">
          <router-link to="/bePartner"><button>Zostań Partnerem</button></router-link>
        </div>
      </div>
        <!--</Flicking>-->
      </div>
    </div>
    <!--END PRODUCTS CARUSEL -->
      <!--Test companies -->
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 1</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/></div>
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
       <div class="company-box2">
        <div class="box-first-part">
          <div><img src="https://upload.mendelhelps.pl/uploads/twoja_grafika1.png" class="logo-small"  alt="Twoje logo"/></div>
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław','https://upload.mendelhelps.pl/uploads/twoja_grafika1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box2">
        <div class="box-first-part">
          <div>
            <img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5>Imię i Nazwisko TEST 5</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
    <!--Test companies -->
      <div class="company-box1">
        <div class="box-first-part">
          <div>
            <img src="https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5>Nazwa Firmy TEST 1</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
      <div class="box-first-part">
        <div>
          <img src="https://upload.mendelhelps.pl/uploads/twoje_logo1.png" class="logo-small" alt="Twoje logo"/>
        </div>
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
        <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych')">Wybierz</button>
        </div>
      </div>
       <div class="company-box1">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław')">Wybierz</button>
        </div>
      </div>
      <div class="company-box1">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 5</h5>
          </div>
          <div class="website-container">
            <p class="website">www.twojafirma.pl</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
         <!--Test companies -->
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 1</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Radosna 56, 99-999 Jawor</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 1','Radosna 56, 99-999 Jawor', 'https://upload.mendelhelps.pl/uploads/twoje_zdjecie1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
      <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 2</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Miła 45, 99-999 Kłodzko</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 2', 'Miła 45, 99-999 Kłodzko', 'https://upload.mendelhelps.pl/uploads/twoje_logo1.png')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Nazwa Firmy TEST 3</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Wesoła 34, 99-999 Wałbrzych</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Nazwa Firmy TEST 3', 'Wesoła 34, 99-999 Wałbrzych')">Wybierz</button>
        </div>
      </div>
       <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 4</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Słoneczna 12, 99-999 Wrocław</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 4', 'Słoneczna 12, 99-999 Wrocław')">Wybierz</button>
        </div>
      </div>
      <div class="company-box">
        <div class="box-first-part">
          <div>
            <h5>Imię i Nazwisko TEST 5</h5>
          </div>
          <div>
            <p>Adres:</p>
            <p>Promienna 23, 99-999 Legnica</p>
          </div>
        </div>
        <div class="select">
          <button @click="showTestCompany('Imię i Nazwisko TEST 5', 'Promienna 23, 99-999 Legnica')">Wybierz</button>
        </div>
      </div>
     <!--Test companies -->
    <!--COMPANIES FIRST PLACE-->
      <div
        v-for="company in companiesFirstPlace"
        v-bind:key="company.id"
        class="company-box"
      >
        <div class="box-first-part">
          <div v-if="company.wyrLogo==1">
            <img :src="company.logo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5 :class="{fontAndSite: company.wyrCzcionka==1}">{{ company.nazwa_producenta }}</h5>
          </div>
          <div v-if="company.wyrCzcionka==1" class="website-container">
            <p class="website">{{ company.adres_strony}}</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>{{ company.adres_producenta }}</p>
          </div>
          <!--<div><img :src="company.logo" class="logo"/></div>-->
        </div>
        <div class="select">
          <button @click="showCompany(company.id)">Wybierz</button>
        </div>
      </div>
      <!--END COMPANIES FIRST PLACE -->
          <div
        v-for="company in wojCompanies"
        v-bind:key="company.id"
        class="company-box"
      >
        <div class="box-first-part">
          <div v-if="company.wyrLogo==1">
            <img :src="company.logo" class="logo-small" alt="Twoje logo"/>
          </div>
          <div>
            <h5 :class="{fontAndSite: company.wyrCzcionka==1}">{{ company.nazwa_producenta }}</h5>
          </div>
          <div v-if="company.wyrCzcionka==1" class="website-container">
            <p class="website">{{ company.adres_strony}}</p>
          </div>
          <div>
            <p>Adres:</p>
            <p>{{ company.adres_producenta }}</p>
          </div>
          <!--<div><img :src="company.logo" class="logo"/></div>-->
        </div>
        <div class="select">
          <button @click="showCompany(company.id)">Wybierz</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompaniesDataService from "../GetCompanies.js";
import categories from "../categoriesHotels.js";
//import { ref } from 'vue'
import axios from "axios";
import location from "../photos/home.png";
import phone from "../photos/phone.png";
import mail from "../photos/mail.png";
import website from "../photos/website.png";
import facebook from "../photos/facebook.png";
import insta from "../photos/insta.png";
import certyfikat from "../photos/certyfikat.png";
import cross from "../photos/cross.png";
import background1 from "../photos/wypoczynek_tlo.jpeg";
import wypoczynek from "../photos/wypoczynek_ikona.png";
import imgLogo from '../photos/Mendel_Helps_logo_male.png';
import imgLogoHelps from '../photos/Mendel_Helps_logo.png';
import Poland from "../photos/poland.png";
import PolandMap from './PolandMap.vue';
export default {
  name: "TheCompanies",
  components: {
    PolandMap
  },
  data() {
    return {
      facilities: [],
      food:[],
      fun:[],
      companies: [],
      companiesFirstPlace:[],
      message: "",
      clickedWoj: "",
      clickedWojId: "",
      hoverWoj: "",
      wojCompanies: [],
      photos: [],
      isShownChoosenCompany: false,
      choosenCompany: [],
      allCategories: categories,
      choosenCategory: "",
      choosenSubcategory: "",
      typesCategory: [],
      companiesAreVisible: false,
      checkedFood: "",
      checkedFacilities: "",
      checkedFun: "",
      images: {
        location: location,
        phone: phone,
        mail: mail,
        website: website,
        facebook: facebook,
        insta: insta,
        certyfikat: certyfikat,
        cross: cross,
        background1: background1,
        wypoczynek: wypoczynek,
        Poland: Poland,
        imgLogo: imgLogo,
        imgLogoHelps: imgLogoHelps
      },
    };
  },
  watch: {
    choosenCategory() {
      let category = this.choosenCategory;
      if (category !== "") {
        let typesArray = this.allCategories.find(
          (option) => option.name == category
        );
        this.typesCategory = typesArray.types;
        console.log(typesArray);
      }
    },
  },
  methods: {
    refreshUsers() {
      CompaniesDataService.retrieveAllCompanies().then((res) => {
        this.companies = res.data;
        console.log(this.companies);
      });
    },
    clickWoj(woj, id) {
      this.companiesAreVisible = false;
      var allMap = document.getElementsByClassName('woj');
      var index = 0, length = allMap.length;
      for ( ; index < length; index++) {
        allMap[index].style.fill = "#6d8b33";
    }
      this.clickedWojId = id;
      var clickedMapPart = document.getElementById(this.clickedWojId);
      clickedMapPart.style.fill = '#d2ca61';
      this.clickedWoj = woj;
      var newTable = this.companies.filter((el) => el.województwo == woj);
      var compFirstPlace = newTable.filter((el)=> el.wyrPierwszeMiesca == '1');
      this.companiesFirstPlace = compFirstPlace;
      console.log(compFirstPlace);
       var restCompanies = newTable.filter((el)=> el.wyrPierwszeMiesca == '0');
      this.wojCompanies = restCompanies;
      console.log(this.wojCompanies);
      console.log(newTable);
      console.log(this.companiesFirstPlace);
      //this.wojCompanies = newTable;
      this.companiesAreVisible = true;
      console.log(this.companiesAreVisible);
      // const filter = document.getElementsByClassName("filters");
      // filter.scrollIntoView();
    },
    filter() {
      // var newTable = this.companies.filter((el)=>el.name==this.filters.name && (el.businessType == this.filters.businessType));
        var checkedFood = [];
        let checkboxes = document.getElementsByName("food");
        //let checkedSubcategories = [];
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            checkedFood.push(checkboxes[i].value);
            checkedFood.toString();
          }
        }
        this.checkedFood = checkedFood;
        console.log(checkedFood);

     var checkedFacilities = [];
        let checkboxes1 = document.getElementsByName("facilities");
        //let checkedSubcategories = [];
        for (var j = 0; j < checkboxes1.length; j++) {
          if (checkboxes1[j].checked) {
            checkedFacilities.push(checkboxes1[j].value);
            checkedFacilities.toString();
          }
        }
        this.checkedFacilities = checkedFacilities;

        console.log(checkedFacilities);
       var checkedFun = [];
        let checkboxes2 = document.getElementsByName("fun");
        //let checkedSubcategories = [];
        for (var k = 0; k < checkboxes2.length; k++) {
          if (checkboxes2[k].checked) {
            checkedFun.push(checkboxes2[k].value);
            checkedFun.toString();
          }
        }
      this.checkedFun = checkedFun;

      var newTable = this.companies.filter(this.filterValues);
      console.log(newTable);
      console.log(this.wojCompanies);
      var compFirstPlace = newTable.filter((el)=> el.wyrPierwszeMiesca == '1');
      this.companiesFirstPlace = compFirstPlace;
      console.log(compFirstPlace);
       var restCompanies = newTable.filter((el)=> el.wyrPierwszeMiesca == '0');
      this.wojCompanies = restCompanies;
      console.log(this.wojCompanies);
      this.companiesAreVisible = true;
      this.choosenCategory = '';
      this.choosenSubcategory = '';
    },


    filterValues(el) {
      console.log(this.checkedFood);
      console.log(this.checkedFacilities);
      console.log(this.checkedFun);
      if (this.checkedFood == "" && this.checkedFacilities == "" && this.checkedFun =="") {
        console.log(this.clickedWoj);
        return el.województwo == this.clickedWoj;
      }
      if (this.checkedFacilities != "" && this.checkedFood == "" && this.checkedFun=="") {
          return el.udogodnienia.includes(this.checkedFacilities) && el.województwo == this.clickedWoj;
      }
      if (this.checkedFacilities == "" && this.checkedFood != "" && this.checkedFun =="") {
          return el.wyzywienie.includes(this.checkedFood) && el.województwo == this.clickedWoj;
      }
      if (this.checkedFacilities == "" && this.checkedFood == "" && this.checkedFun !="") {
          return el.atrakcje.includes(this.checkedFun) && el.województwo == this.clickedWoj;
      }
      if (this.checkedFacilities != "" && this.checkedFood !="" && this.checkedFun =="") {
          return el.udogodnienia.includes(this.checkedFacilities) && el.wyzywienie.includes(this.checkedFood) && el.województwo == this.clickedWoj;
      }
        if (this.checkedFacilities != "" && this.checkedFood !="" && this.checkedFun !="") {
          return el.udogodnienia.includes(this.checkedFacilities) && el.wyzywienie.includes(this.checkedFood) && el.atrakcje.includes(this.checkedFun) && el.województwo == this.clickedWoj;
      }
        if (this.checkedFacilities == "" && this.checkedFood !="" && this.checkedFun !="") {
          return el.wyzywienie.includes(this.checkedFood) && el.atrakcje.includes(this.checkedFun) && el.województwo == this.clickedWoj;
      }
        if (this.checkedFacilities != "" && this.checkedFood == "" && this.checkedFun != "") {
          return el.udogodnienia.includes(this.checkedFacilities) && el.atrakcje.includes(this.checkedFun) && el.województwo == this.clickedWoj;
      }
    },
    showCompany(id) {
      var choosenComp = this.companies.filter((el) => el.id == id);
      this.choosenCompany = choosenComp[0];
      console.log(this.choosenCompany);
      console.log(this.choosenCompany.nazwa_producenta);
      console.log(this.choosenCompany.adres_mailowy);
      this.isShownChoosenCompany = true;
    },
    showTestCompany(nazwa, adres, logoUrl) {
      var choosenCompTest = {
       adres_mailowy: "twojmail@gmail.com",
       adres_producenta: adres,
       adres_strony: "www.twojastrona.pl",
       category: "TEST",
       facebook: "https://twojfacebook.pl",
       id: "39",
       instagram: "https://twojistagram.pl",
       logo: logoUrl,
       nazwa_producenta: nazwa,
       opis: "Tu będzie opis Twojej firmy. \r\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
       subcat: "TEST",
       tel1: "999 999 999",
       tel2: "99 999 99 99",
       tel3: "999 999 999",
       województwo: "dolnośląskie"
    }
    this.choosenCompany = choosenCompTest;
    this.isShownChoosenCompany = true;
    },
    closeWindow() {
      this.isShownChoosenCompany = false;
    },
    goToMap() {
      const map = document.getElementById("map1");
      map.scrollIntoView();
    }
  },
  created() {
    axios
      .get(`./fetchHotels.php`)
      .then((response) => response)
      .then((res) => {
        this.companies = res.data;
        console.log("companies");
        console.log(this.companies);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.log("server is down!!");
      });
        //       var map = document.getElementById('map');
        // map.onmouseleave = function () {
        // var tooltipSpan = document.getElementById('tooltip');
        // console.log(tooltipSpan);
        // tooltipSpan.style.display = 'none';
        // }
        var facilities = this.allCategories.find((el)=>el.name == 'udogodnienia');
        this.facilities = facilities.types;
        var food = this.allCategories.find((el)=>el.name == 'wyżywienie');
        this.food = food.types;
        var fun = this.allCategories.find((el)=>el.name == 'atrakcje');
        this.fun = fun.types;
  },
};
</script>

<style scoped>
.desc-container {
    height: 350px;
    background-size: cover;
    background-position: center;
    margin: -30px 0 0 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;
}
.inside-right-desc1 {
  width: 725px;
  text-align: justify;
}
.inside-right-desc1 p {
  text-align: justify;
}
.map-desc-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    width: 1450px;
    padding: 0;
    margin: 0;
}
.desc-container div .right-desc {
  margin-left: 50px;
    width: 800px;
    align-self: center;
    margin-bottom: 0;
}
.right-desc1 {
  width: 60%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.desc-container img {
  width: 300px;
  margin-bottom: 15px;
  margin-left: 20px;
  transition: 0.3s ease-out;
}
.desc-container img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.find-smth1 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 5px 25px;
  margin: 5px auto 10px auto;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:110px;
  left: 40px;
}
.find-smth1:hover {
    background: none;
  font-weight: 600;
  color: #3084E2;
}
.description2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 1450px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.description2 h2 {
  padding: 0;
  color: white;
  font-size: 25px;
  text-align: center;
}
.description2 p {
  padding: 20px;
  margin:0;
  font-size: 17px;
  color: white;
  line-height: 22px;
}

.description2 p span {
  font-weight: bolder;
}
.second-desc-container {
  width: 1450px;
  margin: 60px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.second-desc-container p {
  text-align: justify;
}
.inside-right-desc1 h2{
  margin:0;
}
.column-left {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 20px;
  width: 60%;
  float: right;
}
.column-left-content {
   width: 700px;
   margin-left: 50px;
}
.column-right {
  width: 40%;
  margin: 30px 0;
  align-self: center;
}
.column-right img {
  float: left;
  width: 400px;
  margin-bottom: 15px;
  margin-left: 20px;
}
.bigger1 {
     font-size: 30px;
    margin: 0;
    padding: 0;
    color: #3084E2;
    font-weight: 600;
    text-align:center;
      display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.go-to-map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  margin: 0;
  padding:0;
  position: relative;
}
.italic {
  font-style: italic;
  font-size: 19px;
  font-weight: 600;
  text-align: center
  }
  .italic1 {
  font-style: italic;
  font-size: 19px;
  font-weight: 500;
  text-align: center
  }
.products-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}
.map1 {
  margin: 10px 0;
}
path {
  fill: #6d8b33;
}
path:hover {
  fill: #d2ca61;
  cursor: pointer;
}
.map-desc {
  margin-top: 50px;
  font-size: 19px;
  font-weight: 600;
}
svg text {
  display: none;
  z-index: 10;
}
svg path:hover text {
  display: block;
  z-index: 10;
}
.filter {
  display: inline-block;
}
.filter h3 {
    margin: 10px auto 20px auto;
}
.filter label {

}
.checked-box label {
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
}
.filter-button {
  display: inline-block;
  height: 35px;
  font-size: 17px;
  padding: 8px 30px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.filter-button:hover {
  cursor: pointer;
  background-color: #75b1f5;
}
select {
  height: 30px;
  padding: 3px;
  font-size: 17px;
  line-height: 30px;
}
h3 {
  margin: 30px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.filters {
  margin: 50px;
}
.products-carusel-above-container {
  width: 1400px;
  overflow:hidden;
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.products-carusel-above-container:hover > .products-carusel-conteiner {
  animation-play-state: paused;
}
.products-carusel-above-container:hover > .products-carusel-conteiner1 {
  animation-play-state: paused;
}
.products-carusel-conteiner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  //animation: 10s ease-in 1s infinite reverse both running carusel;
  animation: 10s linear 0s infinite running carusel;

}
 .products-carusel-conteiner1 {
    display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  //animation: 10s ease-in 1s infinite reverse both running carusel;
  animation: 10s linear 0s infinite running carusel;
 }
@keyframes carusel {
    from {
      display: flex;
      transform: translateX(-50%);
    }

    to {
      display: flex;
      transform: translateX(50%);
    }
  }
.filter-container {
    display: flex;
    flex-align: row;
    justify-content: center;
    align-items: flex-start;
}
.company-box, .company-box1 {
  width: 250px;
  height: 330px;
  margin: 20px;
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
  box-sizing: border-box;
}
.company-box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 330px;
  margin: 20px;
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.25) 0px 8px 32px;
  box-sizing: border-box;
}
.box-first-part {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 87%;
}
.company-box h5, .company-box1 h5, .company-box2 h5 {
  margin: 10px 0 20px 0;
}
.company-box h5 {
  font-weight: 400;
  font-size: 19px;
}
.company-box1 h5 {
  font-weight: 700;
  font-size: 19px;
}
.company-box2 h5 {
  font-weight: 700;
  font-size: 19px;
}
/*styling font-size to bolder distinctions*/
.fontAndSite {
  font-weight: 700!important;
  font-size: 19px;
}
.website-container {
  margin: 0;
  padding: 0;
}
.website {
  margin: 0;
  padding: 0;
  font-style: italic;
}
.boxes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  aling-items: center;
  justify-content: center;
  margin: 50px;
  width: 1450px;
}
button {
  margin: 0;
  padding: 15px;
  color: white;
  background-color: #3084E2;
  box-sizing: border-box;
  border-radius: 20px;
}
button:hover {
  cursor: pointer;
  background-color: #75b1f5;
}
.select {
  width: 100%;
  align-self: end;
}
.select button {
  width: 100%;
  align-self: flex-end;
  border: none;
  padding: 10px;
  font-size: 17px;
  border-radius: 20px;
  background-color: #3084e2;
}
.select button:hover {
  background-color: #75b1f5;
}
.logo {
  width: 150px;
}
.logo-small {
  width: 100px;
  margin: 0;
  padding: 0;
}
.logo-small1 {
  width: 180px;
  margin-top:0;
  padding-top:0;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
.choosen-company-box {
  position: fixed;
  top: 8vh;
  left: 20%;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 100;
  border-radius: 12px;
  border: 5px solid #504DB7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  background: white;
  padding: 0px;
  margin: 60px;
}
.column-container {
  position: relative;
  margin: 30px 30px 0 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.left-column,
right-column {
  margin: 20px;
  display: inline-block;
  height: 100%
}
.logo {
  width: 150px;
  margin-bottom: 30px;
}
.prod-name {
  font-size: 35px;
  color: #504DB7;
  margin-top: 30px;
  font-weight: 500;
}
.prod-adres {
  font-size: 17px;
}
.contact-data {
  background-color: #3084e2;
  font-size: 17px;
  padding: 12px 20px;
  color: white;
  margin: 20px;
  clear: both;
}
.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icons img {
  width: 40px;
  margin-right: 10px;
}
.icon-box {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  clear: both;
}
.icons p {
  font-weight: bold;
  margin: 3px;
  text-align: left;
  float: left;
}
.icons h5 {
  margin: 3px;
  font-weight: 400;
  font-size: 16px;
}
.contact-and-name {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}
.company-desc {
  padding: 30px;
  text-align: justify;
}
.bolder-comp-desc {
  font-weight: bolder;
}
.description {
  clear: both;
  padding: 0 30px 30px 30px;
}
.icon-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  aling-items: flex-start;
}
.cross {
  width: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.woj-text {
  font-size: 30px;
  fill: black;
}
#tooltip2 {
    opacity: 1;
    display:none;
    position: fixed;
    color: #3084E2;
    font-weight: 500;
    background-color: rgb(240,248,255,0.9);
    padding: 10px 15px;
    border-radius: 15px;
}
.active {
  display: block;
  color: red;
}

/*styling checkboxes*/

.container {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 16px;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-box {
  width: 100%;
}
.onefilter-container {
    margin: 20px;
    width: 250px;
    text-align: left;
}
@media only screen and (min-width: 900px) and (max-width: 1250px) {
  .map-desc-container {
    width: 900px;
}
.description2 {
    width: 900px;
}
.desc-container div {
  color: white;
}
.map-cont {
  width: 50%;
}
.desc-container img {
  width: 300px;
}
.right-desc1 {
  width: 60%;
}
.inside-right-desc1 {
  width:auto;
}
.italic {
  width: 500px;
  height: auto;
  margin: 20px;
  box-sizing: border-box;
}
.right-desc1 p {
  text-align: justify;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 22px;
}
.description2 h2 {
  font-size: 20px;
}
.second-desc-container {
  width: 900px;
}
.second-desc-container p {
  text-align: center;
}
.choosen-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.choosen-company-box {
  top: 20px;
  left: calc((100vw - 800px)/2);
  width: 800px;
  padding: 0px;
  margin: 0px;
}
.column-right {
width: 30%;
}
.column-right img {
width: 100%;
padding: 0;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 20px auto;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 100%;
}
.find-smth1 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  padding: 5px 25px;
  margin: 5px auto 10px auto;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:110px;
  left: 40px;
}
.find-smth1:hover {
  font-weight: 600;
  color: black;
}
.filters {
  display: inline-block;
  width: 900px;
}
.filter label {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
}
.filter-button {
  display: inline-block;
  height: 35px;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.boxes-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  jusity-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
}
@media only screen and (min-width: 451px) and (max-width: 900px) {
  .desc-container {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.description2 {
    width: 100%;
    box-sizing: border-box;
}
.desc-container div {
  color: white;
    box-sizing: border-box;
}
.map-cont {
  width: 50%;
}
.desc-container img {
  margin-left: 5px;
  width: 110px;
}
.right-desc1 {
  width: 70%;
}
.find-smth1 {
  font-weight: 600;
  width: 120px;
  font-size: 13px;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:30px;
  left: 0px;
}
.inside-right-desc1 {
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.italic {
  width: 100%;
  height: auto;
  margin: 20px;
  box-sizing: border-box;
}
.right-desc1 p {
  text-align: justify;
  margin: 0;
  padding: 3px;
  font-size: 14px;
  line-height: 1.2;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 22px;
}
.description2 h2 {
  font-size: 20px;
}
.go-to-map-container {
  width: 30%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}
.map-desc-container {
    width: 100%;
    margin-top: 40px;
    box-sizing: border-box;
}
.bigger {
  font-size: 23px;
}
.bigger1 {
   display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bigger1 p {
  text-align: center;
  font-size: 23px;
  margin-top: 15px;
}
.second-desc-container {
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  margin:10px 0;
}
.second-desc-container p {
  text-align: justify;
  width: 100%;
  margin: 0;
  padding: 0;
}
.bigger1 p {
  text-align: center;
  font-size: 23px;
  margin-top: 15px;
}
.column-right {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.column-right img {
width: 80%;
padding: 0;
margin: 0 auto;
}
svg {
  transform: scale(0.7);
  margin: 0;
  paddin: 0;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  margin: 0;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding:0;
}
.filter label {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
}
.filter-button {
  display: inline-block;
  height: 35px;
  width: 250px;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.onefilter-container {
  width: 100%;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.filter label {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 500;
  font-size: 15px;
}
.check-box {
  width: 100%;
  box-sizing: border-box;
  padding: 3px;
}
.filter-button {
  display: inline-block;
  align-self: flex-end;
  height: 35px;
  font-size: 16px;
  padding: 8px 100px;
  border: none;
  margin: 0 20px;
  background-color: #3084E2;
}
.boxes-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  jusity-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.products-container {
  width: 100%;
   box-sizing: border-box;
}
.map-desc {
  width:100%;
  padding: 0;
  margin: 0;
}
.map {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.choosen-company-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: 3px solid #504DB7;
  margin: 0;
}
.column-container {
  position: relative;
  margin: 15px 10px 15px 10px;
}
.left-column,
right-column {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%
}
.left-column {
  justify-content: center;
  align-items: center;
}
.prod-name {
  font-size: 20px;
  margin-top: 0;
}
.logo {
  width: 70%;
}
.right-column {
  justify-content: center;
  align-items: flex-start;
}
.cross {
  width:20px;
  top: 5px;
  right: 5px;
  z-index: 1000;
}
.company-desc {
  margin: 0;
  padding: 0;
}
.icon-box {
  margin: 5px;
}
.icon-desc h5 {
  float: left;
  font-size: 14px;
}
.icons img {
  width: 30px;
  margin-right: 5px;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
}
@media only screen and (max-width: 450px) {
  .desc-container {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    margin-top: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.description2 {
    width: 100%;
    box-sizing: border-box;
}

#tooltip2 {
    opacity: 1;
    display: none;
    position: absolute;
    color: #3084E2;
    background-color: rgba(240, 248, 255, 0.9);
    padding: 10px 15px;
    border-radius: 15px;
    z-index: 1000;
    pointer-events: none;
}

.desc-container div {
  color: white;
    box-sizing: border-box;

}
.map-cont {
  width: 50%;
}
.desc-container img {
  margin-left: 0;
  width: 110px;
}
.right-desc1 {
  width: 100%;
  padding: 10px;
}
.find-smth1 {
  font-weight: 600;
  width: auto;
  font-size: 13px;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #36454f;
  background: none;
  transition: 0.3s ease-out;
  position: absolute;
  top:30px;
  left: 0px;
  z-index: 1000;
}
.inside-right-desc1 {
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.inside-right-desc1 h2 {
  margin: 5px;
}
.italic {
  width: 100%;
  height: auto;
  margin: 10px;
  box-sizing: border-box;
}
.right-desc1 p {
  text-align: justify;
  margin: 0;
  padding: 3px;
  font-size: 14px;
  line-height: 1.2;
}
.desc1 {
  margin-bottom: 0;
  font-size: 19px;
}
.desc2 {
  line-height: 22px;
}
.description2 h2 {
  font-size: 20px;
}
.go-to-map-container {
  width: 30%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;
}
.map-desc-container {
    width: 100%;
    margin-top: 40px;
    box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bigger {
  font-size: 23px;
}
.second-desc-container {
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  margin:10px 0;
}
.second-desc-container p {
  text-align: justify;
  width: 100%;
  margin: 0;
  padding: 0;
}
.bigger1 p {
  text-align: center;
  font-size: 23px;
  margin-top: 15px;
}
.column-right {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.column-right img {
width: 80%;
padding: 0;
margin: 0 auto;
}
svg {
  transform: scale(0.5);
  margin: 0;
  paddin: 0;
}
.column-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  margin: 0;
  align-self: center;
}
.column-left-content {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding:0;
}
.filters {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.filter label {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
}
.filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.onefilter-container {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filter label {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 500;
  font-size: 15px;
}
.check-box {
  width: 100%;
  box-sizing: border-box;
  padding: 3px;
}
.filter-button-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  jusity-content: center;
  align-items: center;
  padding: 10px;
}
.filter-button {
  display: inline-block;
  align-self: flex-end;
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  margin: 0px;
  background-color: #3084E2;
}
.boxes-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  jusity-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.products-container {
  width: 100%;
   box-sizing: border-box;
}
.map-desc {
  width:100%;
  padding: 0;
  margin: 0;
}
.map {
  width: 100%;
  height: auto;
  margin:-100px 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.choosen-company-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border: 3px solid #504DB7;
  margin: 0;
  padding: 0;
}
.column-container {
  position: relative;
  margin: 15px 10px 15px 10px;
}
.left-column,
right-column {
  margin: 10px;
  display: flex;
  flex-direction: column;
  height: 100%
}
.left-column {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.contact-and-name {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.prod-name {
  font-size: 17px;
  margin-top: 0;
}
.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.logo {
  width: 70%;
}
.right-column {
  justify-content: center;
  align-items: flex-start;
}
.cross {
  width:20px;
  top: 5px;
  right: 5px;
  z-index: 1000;
}
.company-desc {
  margin: 0;
  padding: 0;
}
.icon-box {
  margin: 5px;
}
.icon-desc h5 {
  float: left;
  text-align: left;
  font-size: 14px;
}
.icons img {
  width: 25px;
  margin-right: 5px;
}
.products-carusel-conteiner{
  width: 1400px!important;
}
.products-carusel-conteiner1{
  width: 1400px!important;
}
}

.map-paths path {
  cursor: pointer;
}

.region-labels {
  position: relative;
  width: 100%;
  height: 100%;
}

.region-name {
  position: absolute;
  font-size: 10px;
  color: #000000;
  font-weight: bold;
  transform: translate(-50%, -50%);
  font-family: 'Montserrat', sans-serif;
  pointer-events: none;
}

@media (max-width: 768px) {
  .map-cont1 {
    height: 400px;
    margin-top: -50px;
    margin-bottom: 50px;
    transform: scale(1) translateX(0);
    transform-origin: center center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
  }

  .map3 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: auto;
    transform: scale(0.9);
    transform-origin: center center;
  }

  .region-name {
    font-size: 11px;
  }
}

@media only screen and (max-width: 540px) {
  .map-cont1 {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }

  svg {
    transform: scale(0.8);
  }
}

.map-desc {
  text-align: center;
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .map-desc {
    font-size: 16px;
    margin-bottom: 20px;
    padding: 0 20px;
  }
}

.map3 {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-paths path {
  cursor: pointer;
}

.region-labels {
  position: relative;
  width: 100%;
  height: 100%;
}

.region-name {
  position: absolute;
  font-size: 10px;
  color: #000000;
  font-weight: bold;
  transform: translate(-50%, -50%);
  font-family: 'Montserrat', sans-serif;
  pointer-events: none;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .region-name {
    font-size: 10px;
  }
}

.map-cont1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.map3 {
  position: relative;
  width: 612.75696px;
  height: 577.23169px;
  margin: 30px auto;
}

.map3 svg {
  width: 100%;
  height: 100%;
  display: block;
}

.map-paths {
  width: 100%;
  height: 100%;
}

.map-paths path {
  cursor: pointer;
  fill: #6d8b33;
  transition: fill 0.3s ease;
}

.map-paths path:hover {
  fill: #d2ca61;
}

#tooltip3 {
  opacity: 1;
  display: none;
  position: absolute;
  color: #3084E2;
  background-color: rgba(240, 248, 255, 0.9);
  padding: 10px 15px;
  border-radius: 15px;
  z-index: 1000;
  pointer-events: none;
}

@media (max-width: 768px) {
  .map-cont1 {
    height: 400px;
    margin-top: -50px;
    margin-bottom: 50px;
    transform: scale(1) translateX(0);
    transform-origin: center center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
  }

  .map3 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: auto;
    transform: scale(0.9);
    transform-origin: center center;
  }
}

@media only screen and (max-width: 540px) {
  .map-cont1 {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }

  svg {
    transform: scale(0.8);
  }
}
</style>
